.tblImg{
    img{
        width: 60px;
        height: 60px;
        border-radius: 12px;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.18);
        margin-right: 15px;
    }
    video {
        width: 60px;
        height: 60px;
        border-radius: 12px;
        // box-shadow: 0px 0px 24px 0px rgb(0 0 0 / 18%);
        margin-right: 15px;
        object-fit: cover;
    }
}

.btnBuynow{
    border-radius: 8px;
    background: var(--dark-input-button, #191D22);
    border: 0px;
    height: 28px;
    padding: 2px 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 96px;
    color: var(--dark-text, #F6F6F6);
    &:hover{
        border-radius: 8px;
        background: var(--dark-gradient, linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%));
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color: var(--dark-bg-dark-1, #0A0A0B);
    }
}

.listViewTable{
    thead{
        tr{
            th{
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                color: var(--dark-text-grey, #595F6A);
            }
        }
    }
    tbody{
        tr{
            td{
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                color: var(--dark-text, #F6F6F6);
                vertical-align: middle;
                &:nth-child(2){
                    min-width: 300px;
                }

                .fas{
                    color:#fff;
                }

                .profile_iconBtn__TPPPd{
                    &:hover{
                        .fas{
                            color:#0A0A0B;
                        }
                    }
                }
            }
            &:hover{
                border-radius: 12px;
                td{
                    &:first-child{
                        border-start-start-radius: 12px;
                        border-end-start-radius: 12px;
                    }
                    &:last-child{
                        border-start-end-radius: 12px;
                        border-end-end-radius: 12px;
                    }
                }
                background: var(--dark-bg-dark-2, #15171C);
            }
        }
    }
}

body.light-mode{
   
    .btnBuynow{        
        background: var(--light-input-button);        
        color: var(--light-text);
        &:hover{            
            background: var(--light-gradient-light, linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%));            
            color: var(--bg-dark-1);
        }
    }
    
    .listViewTable{
        thead{
            tr{
                th{
                    color: var(--light-text-grey);
                }
            }
        }
        tbody{
            tr{
                td{                    
                    color: var(--light-text);                    
                    .fas{
                        color:#fff;
                    }    
                    .profile_iconBtn__TPPPd{
                        &:hover{
                            .fas{
                                color:#0A0A0B;
                            }
                        }
                    }
                }
                &:hover{
                    background: var(--light-bg-2);
                }
            }
        }
    }
}