

.activityTable{
    &.offerTable{
        tbody{
            tr{
                td{
                    &:nth-child(2){
                        min-width: unset;
                    }
                    .outlineBtn{
                        display: flex;
                        width: 84px;
                        height: 28px;
                        padding: 2px 6px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 8px;
                        border: 1px solid var(--Primary-1, #FFA800);
                        background: var(--dark-borders-hover-button, #2D3139);
                        text-align: center;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        color: var(--Primary-1, #FFA800);
                        transition:all 0.5s;

                        &:hover{
                            background: var(--Primary-1, #FFA800);
                            color:var(--dark-borders-hover-button, #2D3139);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}


body.light-mode{
    .activityTable{
        &.offerTable{
            tbody{
                tr{
                    td{                       
                        .outlineBtn{                           
                            border: 1px solid var(--light-borders-hover-button);
                            background: var(--light-bg-2);                           
                            color: var(--dark-text);                            
                            &:hover{
                                background: var(--Primary-1, #FFA800);                                
                                border-color: var(--dark-borders-hover-button);                            
                            }
                        }
                    }
                }
            }
        }
    }
    
}