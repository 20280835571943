.mycollectionSec {
	margin-top: 125px;

	.collectionSecHeading {
		font-family: var(--font-russo);
		color: var(--dark-white, #fff);
		font-size: 40px;
		font-style: normal;
		font-weight: 400;
		line-height: 60px;

		@media (max-width: 991px) {
			font-size: 36px;
			line-height: 36px;
		}

		@media (max-width: 767px) {
			font-size: 30px;
			line-height: 30px;
		}

		@media (max-width: 565px) {
			font-size: 24px;
			line-height: 24px;
		}
	}

	.collectionSecSubHeading {
		color: var(--dark-text-secondary, #c5c5c5);
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	}

	.backIcon {
		padding: 10px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		background: var(--dark-input-button, #1d2025);
		width: 38px;
		height: 38px;
		border: none;
		color: #fff;
		position: relative;
		top: 10px;
		margin-left: 11px;

		&:hover {
			background: #ffa900;
			color: #1d2025;
		}
	}

	.chooseBlckCnt {
		position: relative;

		.backIcon {
			position: absolute;
			left: 0px;
			top: 10px;

			@media (max-width: 575px) {
				top: -10px;
			}
		}
	}

	.chsBlckChnCnt {
		display: flex;
		padding: 60px 20px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 12px;
		flex: 1 0 0;
		margin: 5px 0px;
		border-radius: 16px;
		border: 1px solid var(--dark-borders-hover-button, #2d3139);
		background: rgb(255, 169, 0);
		background: linear-gradient(153deg,
				rgba(255, 169, 0, 1) 0%,
				rgba(255, 255, 255, 0) 50%,
				rgba(255, 237, 174, 1) 100%);
		position: relative;
		overflow: hidden;
		transition: all 0.5s;

		&.ercWizard {
			padding: 40px 20px;
		}

		&:before {
			content: '';
			background: var(--dark-bg-2, #14161b);
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0px;
			top: 0px;
			border-radius: 0px;
			z-index: 1;
			transition: all 0.5s;
		}

		&:hover {
			cursor: pointer;

			&::before {
				width: calc(100% - 4px);
				height: calc(100% - 4px);
				left: 2px;
				top: 2px;
				border-radius: 14px;
			}
		}

		@media (max-width: 565px) {
			padding: 20px 10px;
		}

		.content {
			position: relative;
			z-index: 2;

			h3 {
				color: var(--dark-text-secondary, #c5c5c5);
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 24px;
			}
		}
	}

	.createCollectionCard {
		display: flex;
		width: 100%;
		padding: 16px;
		flex-direction: column;
		align-items: flex-start;
		border-radius: 16px;
		background: var(--dark-bg-2, #14161b);

		hr {
			border-color: var(--dark-input-button, #1d2025);
		}

		.dropdown {
			.dropdown-toggle {
				display: flex;
				height: 40px;
				padding: 8px 12px;
				align-items: center;
				align-self: stretch;
				border-radius: 8px;
				border: 1px solid var(--dark-borders-hover-button, #2d3139);
				background: var(--dark-input-button, #1d2025);
				backdrop-filter: blur(19px);
				color: var(--dark-text, #f6f6f6);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				min-width: 200px;

				&:after {
					display: none;
				}
			}

			.dropdown-menu {
				padding: 12px 16px;
				border-radius: 8px;
				background: rgba(37, 42, 49, 0.7);
				backdrop-filter: blur(19px);
				min-width: 200px;

				a {
					padding: 8px 0px;
					color: var(--dark-text, #f6f6f6);
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px;

					&:hover {
						background: transparent;
						color: #ffa900;
						cursor: pointer;
					}
				}
			}

			&.err {
				.dropdown-toggle {
					border-color: var(--Red, #fe2626);
				}
			}
		}

		.walletCnt {
			display: flex;
			padding: 12px;
			align-items: center;
			align-self: stretch;
			border-radius: 12px;
			border: 1px solid var(--dark-borders-hover-button, #2d3139);
			backdrop-filter: blur(19px);

			.walletLabel {
				display: flex;
				height: 24px;
				padding: 4px 8px;
				align-items: center;
				border-radius: 8px;
				background: var(--dark-input-button, #1d2025);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px;

				&.successLabel {
					color: var(--Green, #00b976);
				}

				&.dangerLabel {
					color: var(--Red, #fe2626);
				}
			}

			h3 {
				overflow: hidden;
				color: var(--dark-text, #f6f6f6);
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				margin-bottom: 0px;
			}
		}

		form {
			.formLabel {
				color: var(--dark-text, #f6f6f6);
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
				margin-bottom: 10px;
				display: block;
			}

			.chooseFileBtn {
				width: 110px;
				height: 28px;
				display: flex;
				padding: 2px 12px;
				justify-content: center;
				align-items: center;
				border-radius: 8px;
				border: 1px solid var(--Primary-1, #ffa800);
				background: var(--dark-input-button, #1d2025);
				position: relative;
				overflow: hidden;

				&:hover {
					cursor: pointer;
				}

				input[type='file'] {
					opacity: 0;
				}

				label {
					text-align: center;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					// background: var(
					// 	--light-gradient-light,
					// 	linear-gradient(137deg, #ffab00 6.3%, #ffd76f 95.08%)
					// );
					background-image: var(--light-gradient-light,
							linear-gradient(137deg, #ffab00 6.3%, #ffd76f 95.08%));
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					color: var(--Primary-1);
					position: absolute;
					left: 0px;
					top: 0px;
					width: 100%;
					height: 100%;
					margin: 0px;
					line-height: 26px;

					&:hover {
						cursor: pointer;
					}
				}
			}

			p {
				color: var(--dark-text, #f6f6f6);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				margin-bottom: 5px;

				&.greyTxt {
					color: var(--dark-text-grey, #797f8a);
				}
			}

			input,
			.form-control {
				height: 40px;
				padding: 8px 12px;
				align-items: center;
				border-radius: 8px;
				border: 1px solid var(--dark-borders-hover-button, #2d3139);
				background: var(--dark-input-button, #1d2025);
				backdrop-filter: blur(19px);
				color: #fff;

				&.err {
					border-color: var(--Red, #fe2626);
				}
			}

			textarea {
				&.form-control {
					height: 100px;
				}
			}

			.urlFieldCnt {
				.input-group {
					.input-group-text {
						padding-right: 0px;						
						border-color: var(--dark-borders-hover-button, #2d3139);
						border-top-left-radius: 8px;
						border-bottom-left-radius: 8px;
						border-right: 0px;
						background: var(--dark-input-button, #1d2025);
						color: #f6f6f6;
					}

					input {
						border-left: 0px;
						padding-left: 5px;
						border-top-left-radius: 0px;
						border-bottom-left-radius: 0px;

						&:focus {
							box-shadow: none;
						}
					}

					&.err {

						.input-group-text,
						input {
							border-color: var(--Red, #fe2626);
						}
					}
				}

				.socilaMediaIconCnt {
					width: 40px;
					height: 40px;
					display: flex;
					padding: 10px;
					justify-content: center;
					align-items: center;
					border-radius: 8px;
					background: var(--dark-input-button, #1d2025);
				}

				&.traitsCnt {
					.input-group {
						input {
							border-left: 1px solid var(--dark-borders-hover-button, #2d3139);
							border-top-left-radius: 8px;
							border-bottom-left-radius: 8px;
							padding-left: 12px;
							border-right: 0px;
							border-top-right-radius: 0px;
							border-bottom-right-radius: 0px;
						}

						.input-group-append {
							.input-group-text {
								border-right: 1px solid var(--dark-borders-hover-button, #2d3139);
								padding-right: 12px;
								border-top-left-radius: 0px;
								border-bottom-left-radius: 0px;
								border-top-right-radius: 8px;
								border-bottom-right-radius: 8px;
								color: var(--dark-text-grey, #797f8a);
							}
						}
					}

					.socilaMediaIconCnt {
						img {
							filter: brightness(100);
						}
					}
				}
			}

			.errLabel {
				overflow: hidden;
				color: var(--Red, #fe2626);
				text-align: right;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
			}

			.alert {
				padding: 12px;
				border-radius: 12px;
				background: rgba(254, 38, 38, 0.1);
				backdrop-filter: blur(19px);
				border: none;
				color: var(--Red, #fe2626);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
			}
		}

		.imgCnt {
			width: 160px;
			height: 160px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 8px;
			border: 1px dashed var(--dark-borders-hover-button, #2d3139);
			background: var(--dark-input-button, #1d2025);
			backdrop-filter: blur(19px);
			margin-bottom: 10px;
			position: relative;

			&.err {
				border-color: var(--Red, #fe2626);
			}

			img {
				&.logoImg {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 8px;
				}
			}

			.editIcon {
				width: 136px;
				height: 136px;
				padding: 10px;
				justify-content: center;
				align-items: center;
				border-radius: 8px;
				background: rgba(255, 255, 255, 0.16);
				backdrop-filter: blur(19px);
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				display: none;
			}

			&:hover {
				.editIcon {
					display: flex;
				}
			}
		}

		.coverCnt {
			width: 100%;
			height: 160px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 8px;
			border: 1px dashed var(--dark-borders-hover-button, #2d3139);
			background: var(--dark-input-button, #1d2025);
			backdrop-filter: blur(19px);
			margin-bottom: 10px;
			position: relative;

			&.err {
				border-color: var(--Red, #fe2626);
			}

			img {
				&.coverImg {
					width: 100%;
					height: 100%;
					min-height: unset;
					object-fit: cover;
					border-radius: 8px;
				}
			}

			.editIcon {
				width: calc(100% - 24px);
				height: calc(100% - 24px);
				padding: 10px;
				justify-content: center;
				align-items: center;
				border-radius: 8px;
				background: rgba(255, 255, 255, 0.16);
				backdrop-filter: blur(19px);
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				display: none;

				.editUplCnt {
					width: 18px;
					height: 18px;
					position: relative;

					input[type='file'] {
						opacity: 0;
						width: 18px;
						height: 18px;
						position: absolute;
						left: 0px;
						top: 0px;
						z-index: 2;
					}

					label {
						position: absolute;
						left: 0px;
						top: 0px;
						z-index: 3;
						margin-bottom: 0px;

						&:hover {
							cursor: pointer;
						}
					}
				}
			}

			&:hover {
				.editIcon {
					display: flex;
				}
			}
		}

		.imgContainer {
			width: 160px;
		}

		.coverContainer {
			width: calc(100% - 185px);
		}

		.blackBtn {
			background: var(--dark-input-button, #1d2025);
			color: var(--dark-text-grey, #797f8a);

			&:hover {
				color: #ffab00;
			}
		}

		.getVerified {
			display: flex;
			width: 112px;
			height: 28px;
			padding: 2px 6px;
			justify-content: center;
			align-items: center;
			border-radius: 8px;
			border: 1px solid var(--Primary-1, #ffa800);
			background: var(--dark-input-button, #1d2025);
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;
			color: var(--Primary-1, #ffa800);
		}

		.freezeCnt {
			border-radius: 16px;
			background: var(--dark-input-button, #1d2025);
			backdrop-filter: blur(19px);
			display: flex;
			padding: 8px 12px;
			align-items: center;

			.formLabel {
				margin-bottom: 5px;
			}

			p {
				overflow: hidden;
				color: var(--dark-text-secondary, #c5c5c5);
				text-overflow: ellipsis;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px;
			}

			.switch {
				position: relative;
				display: inline-block;
				width: 46px;
				height: 24px;
			}

			.switch input {
				opacity: 0;
				width: 0;
				height: 0;
			}

			.slider {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(255, 255, 255, 0.1);
				-webkit-transition: 0.4s;
				transition: 0.4s;
			}

			.slider:before {
				position: absolute;
				content: '';
				height: 20px;
				width: 20px;
				left: 3px;
				bottom: 2px;
				background-color: white;
				-webkit-transition: 0.4s;
				transition: 0.4s;
			}

			input:checked+.slider {
				background-color: #faba34;
			}

			input:focus+.slider {
				box-shadow: 0 0 1px #faba34;
			}

			input:checked+.slider:before {
				-webkit-transform: translateX(20px);
				-ms-transform: translateX(20px);
				transform: translateX(20px);
			}

			.slider.round {
				border-radius: 34px;
			}

			.slider.round:before {
				border-radius: 50%;
			}
		}
	}

	.collapseCnt {
		width: 100%;

		.collapseHead {
			border-bottom: 1px solid rgba(255, 255, 255, 0.14);

			.collapseHeading {
				color: #fff;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
			}
		}

		.collapseBody {
			width: 100%;
			padding: 15px 0px;

			p {
				color: #fff;
				font-weight: 400;

				a {
					color: #faba34;
				}
			}

			.collapseCard {
				border-radius: 16px;
				background: var(--dark-bg-dark-2, #15171c);
				border: 1px solid var(--dark-bg-dark-2, #15171c);
				padding: 20px;
				width: 100%;
				margin-bottom: 10px;
				transition: all 0.5s;

				&:hover {
					cursor: pointer;
					background: transparent;
					border-color: #faba34;
				}

				h3 {
					color: #fff;
					font-size: 18px;
					font-style: normal;
					font-weight: 500;
					margin-bottom: 15px;
				}

				p {
					color: #fff;
					font-size: 14px;
					font-style: normal;
					font-weight: 300;
					line-height: 24px;
					margin-bottom: 10px;
				}
			}
		}
	}

	&.mintSec {
		.createCollectionCard {
			.coverCnt {
				height: 500px;
			}

			.addIconCnt {
				width: 40px;
				height: 40px;
				padding: 10px;
				background: var(--dark-input-button, #1d2025);
				border-radius: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			a {
				overflow: hidden;
				color: var(--dark-text, #f6f6f6);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
			}

			.dropdown {
				&.collectionDD {
					.dropdown-menu {
						border-radius: 8px;
						background: rgba(37, 42, 49, 0.7);
						backdrop-filter: blur(19px);
					}

					h3 {
						color: var(--dark-text, #f6f6f6);
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px;
						margin-bottom: 0px;
					}

					p {
						color: var(--dark-text-grey, #797f8a);
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 16px;
						margin-bottom: 0px;
					}

					.createCollectionrow {
						color: #faba34;
					}
				}
			}
		}
	}
}

.listViewTable {
	&.collapseTable {
		border-collapse: separate;
		border-spacing: 0 16px;

		tbody {
			tr {
				td {
					background: var(--dark-bg-2, #14161b);

					&:first-child {
						border-top-left-radius: 16px;
						border-bottom-left-radius: 16px;
					}

					&:last-child {
						border-bottom-right-radius: 16px;
						border-top-right-radius: 16px;
					}

					&:nth-child(2) {
						min-width: unset;
					}
				}
			}
		}
	}
}

.curMdl {
	&.createScsMdl {
		max-width: 420px;

		.modal-content {
			padding: 20px;
			border-radius: 20px;
		}

		.createContent {
			position: relative;

			.closeBtn {
				position: absolute;
				right: 0px;
				top: 0px;
			}
		}

		h3 {
			&.walletSubHeading {
				color: var(--dark-text-secondary, #c5c5c5);
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
			}
		}
	}

	form {

		input,
		.form-control {
			height: 40px;
			padding: 8px 12px;
			align-items: center;
			border-radius: 8px;
			border: 1px solid var(--dark-borders-hover-button, #2d3139);
			background: var(--dark-input-button, #1d2025);
			backdrop-filter: blur(19px);
			color: #fff;
		}

		textarea {
			&.form-control {
				height: 100px;
			}
		}
	}
}

body.light-mode {
	form {

		input,
		.form-control {
			border: 1px solid var(--light-borders-hover-button);
			background: var(--light-input-button);
			-webkit-backdrop-filter: blur(19px);
			backdrop-filter: blur(19px);
			color: var(--light-text);
		}

		textarea {
			&.form-control {
				height: 100px;
			}
		}
	}
}

.verifyPopOver {
	.popover {
		border-radius: 12px;
		background: var(--dark-alert-tip, #3f4349);
		padding: 12px 16px;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.popover-body {
			padding: 0px;
			color: var(--dark-text, #f6f6f6);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
		}

		.arrow {
			&::before {
				border-top-color: #3f4349;
			}

			&::after {
				border-top-color: #3f4349;
			}
		}
	}

	.custCheck {
		display: block;
		position: relative;
		padding-left: 26px;
		margin-bottom: 12px;
		cursor: pointer;
		color: var(--dark-text, #f6f6f6);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}

		&:hover {
			input~.checkmark {
				background-color: #00b976;
			}
		}
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		background-color: rgba(45, 49, 57, 1);
	}

	.custCheck input:checked~.checkmark {
		background-color: #00b976;
	}

	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	.custCheck input:checked~.checkmark:after {
		display: block;
	}

	.custCheck .checkmark:after {
		left: 7px;
		top: 4px;
		width: 6px;
		height: 10px;
		border: solid #0a0a0b;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

body.light-mode {
	.listViewTable {
		&.collapseTable {
			tbody {
				tr {
					td {
						background: var(--light-bg-2);

						&:nth-child(2) {
							min-width: unset;
						}
					}
				}
			}
		}
	}

	.mycollectionSec {
		.collectionSecHeading {
			color: var(--light-text);
		}

		.collectionSecSubHeading {
			color: var(--light-text-secondary);
		}

		.createCollectionCard {
			background: var(--light-bg-2);

			.walletCnt {
				border-color: var(--light-borders-hover-button);

				h3 {
					color: var(--light-text);
				}

				.walletLabel {
					background: #afb5bd;
				}
			}

			.imgCnt {
				border-color: var(--light-borders-hover-button);
				background: var(--light-input-button);
			}

			form {
				.formLabel {
					color: var(--light-text);
				}

				p {
					color: var(--light-text);
				}

				.chooseFileBtn {
					border: 1px solid var(--Primary-1);
					background: var(--light-input-button);

					label {
						background: var(--light-gradient-light);
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}

				span:not([class="text-danger"]) {
					color: var(--dark-text, #000000) !important;
					font-size: 14px;
					font-style: normal;
					// font-weight: 400;
					// line-height: 20px;
					// margin-bottom: 5px;

					&.greyTxt {
						color: var(--dark-text-grey, #797f8a);
					}
				}

				input,
				.form-control {
					border: 1px solid var(--light-borders-hover-button);
					background: var(--light-input-button);
					-webkit-backdrop-filter: blur(19px);
					backdrop-filter: blur(19px);
					color: var(--light-text);
				}

				.urlFieldCnt .input-group .input-group-text {
					border-color: var(--light-borders-hover-button);
					background: var(--light-input-button);
					color: var(--light-text-secondary);
				}

				.urlFieldCnt.traitsCnt .input-group input {
					border-left: 1px solid var(--light-borders-hover-button);
				}

				.urlFieldCnt.traitsCnt .input-group .input-group-append .input-group-text {
					border-color: var(--light-borders-hover-button);
					background: var(--light-input-button);
					border-left: 1px solid var(--light-borders-hover-button);
				}

				.urlFieldCnt .socilaMediaIconCnt {
					img {
						filter: brightness(0.3);
					}
				}

				.freezeCnt {
					background: var(--light-input-button);

					.slider {
						background-color: rgba(0, 0, 0, 0.1);
					}

					input:checked+.slider {
						background-color: var(--Primary-1);
					}
				}
			}

			.dropdown .dropdown-toggle {
				border: 1px solid var(--light-borders-hover-button);
				background: var(--light-input-button);
				color: var(--light-text);

				img {
					&.ml-auto {
						filter: brightness(0.3);
					}
				}
			}

			.socilaMediaIconCnt {
				background: var(--light-input-button);

				img {
					filter: brightness(0.3);
				}
			}

			hr {
				border-color: var(--light-input-button);
			}

			.blackBtn {
				background: var(--light-input-button);
				color: var(--light-text-grey);

				&:hover {
					color: var(--Primary-1);
				}
			}
		}

		.backIcon {
			background: var(--light-input-button);
			color: var(--light-text);
		}

		.collapseCnt {
			.collapseHead {
				border-bottom: 1px solid rgba(0, 0, 0, 0.14);

				.collapseHeading {
					color: var(--light-text);
				}

				img {
					filter: brightness(0.3);
				}
			}

			.collapseBody {
				p {
					color: var(--light-text);
				}

				.collapseCard {
					background: var(--light-bg-2);
					border: 1px solid var(--light-bg-2);

					&:hover {
						border-color: Var(--Primary-1);
						background-color: transparent;
					}
				}

				h3 {
					color: var(--light-text);
				}
			}
		}

		&.mintSec {
			.createCollectionCard {
				.addIconCnt {
					background: var(--light-input-button);

					img {
						filter: brightness(0);
					}
				}

				a {
					color: var(--light-text);
				}
			}
		}
	}

	.chsBlckChnCnt {
		border: 1px solid var(--light-borders-hover-button);
		background: rgb(255, 169, 0);
		background: linear-gradient(153deg,
				rgba(255, 169, 0, 1) 0%,
				rgba(255, 255, 255, 0) 50%,
				rgba(255, 237, 174, 1) 100%);

		&:before {
			content: '';
			background: var(--light-bg-2);
		}

		.content {
			h3 {
				color: var(--light-text-secondary);
			}
		}
	}
}


.custom-tooltip {
	background: rgba(50, 55, 64, 0.7);
	// border: 1px solid #323740;	
	// box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	max-width: 200px;
	min-width: 140px;
	width: 100%;
	border-radius: 8px;
	padding: 0px;
	text-align: center;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -10px;
		border-width: 10px;
		border-style: solid;
		border-color: rgba(50, 55, 64, 0.7) transparent transparent transparent;
	}

	.tooltip-image img {
		width: 100%;
		height: 140px;
		border-radius: 8px 8px 0px 0px;
		object-fit: cover;
		object-position: center;
	}

	.tooltip-content {
		padding: 12px 16px;

		h3 {
			margin: auto;
			font-size: 16px;
			font-weight: 600;
			color: #f6f6f6;
		}

		p {
			margin: 0;
			font-size: 14px;
			color: var(--dark-text-secondary);
		}
	}
}

.custombar-tooltip {
	background: rgba(50, 55, 64, 0.7);
	padding: 0;
	border-radius: 8px;
	text-align: center;
	max-width: 200px;
	min-width: 140px;
	width: 100%;

	&::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -10px;
		border-width: 10px;
		border-style: solid;
		border-color: rgba(50, 55, 64, 0.7) transparent transparent transparent;
	}

	.tooltip-content {
		padding: 12px 16px;

		h3 {
			margin: auto;
			font-size: 16px;
			font-weight: 600;
			color: #f6f6f6;
		}

		p {
			margin: 0;
			font-size: 14px;
			color: var(--dark-text-secondary);
		}
	}
}





.light-mode .userDescpara {
	color: #000000;
}

body.light-mode {
	.listViewTable {
		&.collapseTable {
			tbody {
				tr {
					td {
						background: var(--light-bg-2);

						&:nth-child(2) {
							min-width: unset;
						}
					}
				}
			}
		}
	}

	.mycollectionSec {
		.collectionSecHeading {
			color: var(--light-text);
		}

		.collectionSecSubHeading {
			color: var(--light-text-secondary);
		}

		.createCollectionCard {
			background: var(--light-bg-2);

			.walletCnt {
				border-color: var(--light-borders-hover-button);

				h3 {
					color: var(--light-text);
				}

				.walletLabel {
					background: var(--light-input-button);
				}
			}

			.imgCnt {
				border-color: var(--light-borders-hover-button);
				background: var(--light-input-button);
			}

			form {
				.formLabel {
					color: var(--light-text);
				}

				p {
					color: var(--light-text);
				}

				.chooseFileBtn {
					border: 1px solid var(--Primary-1);
					background: var(--light-input-button);

					label {
						background: var(--light-gradient-light);
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}

				input,
				.form-control {
					border: 1px solid var(--light-borders-hover-button);
					background: var(--light-input-button);
					-webkit-backdrop-filter: blur(19px);
					backdrop-filter: blur(19px);
					color: var(--light-text);
				}

				.urlFieldCnt .input-group .input-group-text {
					border-color: var(--light-borders-hover-button);
					background: var(--light-input-button);
					color: var(--light-text-secondary);
				}

				.urlFieldCnt.traitsCnt .input-group input {
					border-left: 1px solid var(--light-borders-hover-button);
				}

				.urlFieldCnt.traitsCnt .input-group .input-group-append .input-group-text {
					border-color: var(--light-borders-hover-button);
					background: var(--light-input-button);
					border-left: 1px solid var(--light-borders-hover-button);
				}

				.urlFieldCnt .socilaMediaIconCnt {
					img {
						filter: brightness(0.3);
					}
				}

				.freezeCnt {
					background: var(--light-input-button);

					.slider {
						background-color: rgba(0, 0, 0, 0.1);
					}

					input:checked+.slider {
						background-color: var(--Primary-1);
					}
				}
			}

			.dropdown .dropdown-menu {
				background: rgba(224, 227, 231, 0.7);

				a {
					color: var(--light-text);
				}
			}

			.dropdown .dropdown-toggle {
				border: 1px solid var(--light-borders-hover-button);
				background: var(--light-input-button);
				color: var(--light-text);

				img {
					&.ml-auto {
						filter: brightness(0.3);
					}
				}
			}

			.socilaMediaIconCnt {
				background: var(--light-input-button);

				svg path[fill='white'] {
					fill: var(--light-text);
				}

				img {
					filter: brightness(0.3);
				}
			}

			hr {
				border-color: var(--light-input-button);
			}

			.blackBtn {
				background: var(--light-input-button);
				color: var(--light-text-grey);

				&:hover {
					color: var(--Primary-1);
				}
			}

			.coverCnt {
				border: 1px dashed var(--light-borders-hover-button);
				background: var(--light-input-button);
			}
		}

		.backIcon {
			background: var(--light-input-button);
			color: var(--light-text);
		}

		.collapseCnt {
			.collapseHead {
				border-bottom: 1px solid rgba(0, 0, 0, 0.14);

				.collapseHeading {
					color: var(--light-text);
				}

				img {
					filter: brightness(0.3);
				}
			}

			.collapseBody {
				p {
					color: var(--light-text);
				}

				.collapseCard {
					background: var(--light-bg-2);
					border: 1px solid var(--light-bg-2);

					&:hover {
						border-color: Var(--Primary-1);
						background-color: transparent;
					}
				}

				h3 {
					color: var(--light-text);
				}
			}
		}

		&.mintSec {
			.createCollectionCard {
				.addIconCnt {
					background: var(--light-input-button);

					img {
						filter: brightness(0);
					}
				}

				a {
					color: var(--light-text);
				}
			}
		}
	}

	.chsBlckChnCnt {
		border: 1px solid var(--light-borders-hover-button);
		background: rgb(255, 169, 0);
		background: linear-gradient(153deg,
				rgba(255, 169, 0, 1) 0%,
				rgba(255, 255, 255, 0) 50%,
				rgba(255, 237, 174, 1) 100%);

		&:before {
			content: '';
			background: var(--light-bg-2);
		}

		.content {
			h3 {
				color: var(--light-text-secondary);
			}
		}
	}
}

.filterGroup {
	position: relative;

	.collectionFilter {
		max-height: 230px;
		overflow-y: scroll;

		&.filterSubmenu {
			width: 100%;
			border-radius: 8px;
			background: rgba(37, 42, 49, 0.7);
			backdrop-filter: blur(19px);
			padding: 12px 16px;
			position: absolute;
			left: 0px;
			top: 45px;
			z-index: 2;

			.filterRow {
				width: 100%;

				h3 {
					&.heading {
						color: var(--dark-text, #f6f6f6);
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: 24px;
						margin-bottom: 2px;
					}
				}

				p {
					overflow: hidden;
					color: var(--dark-text-secondary, #c5c5c5);
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px;
					margin-bottom: 2px;

					span {
						color: var(--dark-text, #f6f6f6);
					}
				}
			}

			hr {
				background: var(--dark-white, #fff);
				opacity: 0.1;
			}
		}
	}
}

.filterSubHead {
	h3 {
		overflow: hidden;
		color: var(--dark-text, #f6f6f6);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: 0px;
	}
}

/*---------------------------------------Skeleton---------------------------------------------------------*/
.banner-skeleton {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: calc(100% - 96px);
	}
}

.details-skeleton {
	.imgtag {
		text-align: center;
		margin-top: -50px;
		margin-bottom: 30px;

		span {
			width: 120px;
			height: 120px;
			border-radius: 12px;
		}
	}

	.userName {
		line-height: 32px;
		margin-bottom: 4px;
		margin-top: auto;
		margin-left: auto;
		margin-right: auto;
		width: 40%;
	}

	.usernameby {
		width: 30%;
	}

	.userDescpara {
		line-height: 20px;
		margin-bottom: 4px;
	}

	.readmorebtn {
		line-height: 20px;
		width: 20%;
	}
}

.stat-skeleton {
	padding: 0px 16px;
	margin-top: 28px;
	margin-bottom: 32px;

	span {
		height: 32px;
		width: 100%;
		border-radius: 5px;
	}
}

// .nft-grid-skeleton {
// .gridrow {
// 	display: grid;
// 	gap: 28px;
// 	transition: all 1s linear;
// 	@media (min-width: 1200px) {
// 		&.g-col-5 {
// 			grid-template-columns: repeat(
// 				auto-fit,
// 				[col-start] calc(calc(100% - calc(28px * 4)) / 5) [col-end]
// 			);
// 		}

// 		&.g-col-6 {
// 			grid-template-columns: repeat(
// 				auto-fit,
// 				[col-start] calc(calc(100% - calc(28px * 5)) / 6) [col-end]
// 			);
// 		}
// 	}

// 	@media only screen and (max-width: 1199px) and (min-width: 992px) {
// 		&.g-col-5 {
// 			grid-template-columns: auto auto auto auto;
// 		}

// 		&.g-col-6 {
// 			grid-template-columns: repeat(
// 				auto-fit,
// 				[col-start] calc(calc(100% - calc(28px * 4)) / 5) [col-end]
// 			);
// 		}
// 	}

// 	@media only screen and (max-width: 991px) and (min-width: 768px) {
// 		&.g-col-5 {
// 			grid-template-columns: auto auto auto auto;
// 		}

// 		&.g-col-6 {
// 			grid-template-columns: auto auto auto auto;
// 		}
// 	}
// 	@media only screen and (max-width: 767px) and (min-width: 600px) {
// 		&.g-col-5 {
// 			grid-template-columns: auto auto auto;
// 		}

// 		&.g-col-6 {
// 			grid-template-columns: auto auto auto;
// 		}
// 	}
// 	@media only screen and (max-width: 526px) and (min-width: 599px) {
// 		&.g-col-5 {
// 			grid-template-columns: auto auto;
// 		}

// 		&.g-col-6 {
// 			grid-template-columns: auto auto;
// 		}
// 	}
// 	@media only screen and (max-width: 525px) {
// 		&.g-col-5 {
// 			grid-template-columns: auto;
// 		}

// 		&.g-col-6 {
// 			grid-template-columns: auto;
// 		}
// 	}
// 	.grid-item {
// 		border-radius: 12px;
// 		min-height: 220px;
// 		max-height: 228px;
// 		position: relative;
// 		.img-placeholder {
// 			position: absolute;
// 			top: 5%;
// 			left: 50%;
// 			transform: translateX(-50%);
// 			border-radius: 12px;
// 			width: 85%;
// 			height: 60%;
// 		}
// 		.item-img {
// 			border-radius: 12px;
// 			width: 100%;
// 			height: 100%;
// 			position: relative;
// 		}
// 		.item-details {
// 			position: absolute;
// 			height: 30%;
// 			bottom: 0;
// 			left: 0;
// 			right: 0;
// 			.details {
// 				padding: 10px;
// 				gap: '5px';
// 				span {
// 					width: 80%;
// 				}
// 			}
// 		}
// 	}
// }
// }
/*---------------------------------------Skeleton---------------------------------------------------------*/

.gItemImg {
	position: relative;
	border-radius: 12px 12px 0 0;

	.idTag {
		position: absolute;
		top: 15px;
		left: 15px;
		color: var(--dark-text, #f6f6f6);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		border-radius: 8px;
		background: rgba(0, 0, 0, 0.4);
		backdrop-filter: blur(19px);
		display: inline-flex;
		min-height: 28px;
		padding: 4px 8px;
		align-items: center;
	}

	img.itemImage {
		min-height: 160px;
		max-height: 168px;
		border-radius: 12px 12px 0 0;
	}
}





.imgContainer {
	&.mbVCh {
		@media only screen and (max-width: 575px) {
			flex: 0 0 100%;
			margin-right: 0px !important;

			.imgCnt {
				width: 100%;
				height: auto;
			}
		}
	}
}

.coverContainer {
	&.mbVCh {
		@media only screen and (max-width: 575px) {
			flex: 0 0 100%;
		}
	}
}


.clcUrl {
	border: 1px solid var(--dark-borders-hover-button, #2d3139);
	background: var(--dark-input-button, #1d2025);

	body.light-mode & {
		border-color: var(--light-borders-hover-button);
		background-color: var(--light-input-button);
	}

	-webkit-backdrop-filter: blur(19px);
	white-space: nowrap;
	overflow: auto;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0px 12px;
	border-radius: 8px;

	input {
		border: 0px !important;
		padding: 0px 4px !important;
		outline: 0px;
	}
}

.ercType {
	font-size: 34px;
	font-weight: 600;
}

.filter_px-0 {
	padding-left: 0;
	padding-right: 0;

	h1 {
		padding: 0px 20px;
	}

	.hdfil {
		padding: 5px 20px;
	}
}