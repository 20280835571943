.userDetails {
	.imgtag {
		text-align: center;
		margin-top: -50px;
		margin-bottom: 30px;
		img {
			width: 120px;
			height: 120px;
			border-radius: 12px;
			box-shadow: 0px 0px 27px 0px #fff;
		}
		span {
			width: 120px;
			height: 120px;
			border-radius: 12px;
		}
	}
	.userName {
		font-size: 20px;
		font-family: var(--font-russo);
		font-style: normal;
		font-weight: 400;
		line-height: 32px;
		margin-bottom: 4px;
	}
	.usernameby {
		span.fw-400 {
			font-weight: 400;
			color: #f6f6f6;
		}
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 12px;
	}
}

.userDescpara {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	color: var(--dark-text, #f6f6f6);
	margin-bottom: 4px;
}

.readmorebtn {
	background-color: transparent;
	border: 0;
	padding: 0;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	color: var(--dark-text-grey, #595f6a);
}

.socialmediaBtnGrid {
	display: grid;
	grid-template-columns: 40px 40px 40px;
	gap: 10px;
	margin-top: 15px;
}

.iconBtn {
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	border: 0px;
	width: 40px;
	height: 40px;
	text-align: center;
	box-shadow: none !important;
	outline: none !important;
	&:hover,
	&:focus {
		background: var(
			--dark-gradient,
			linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%)
		) !important;
	}
}

.dropdownMenu {
	border-radius: 8px;
	background: rgba(37, 42, 49, 0.7);
	backdrop-filter: blur(19px);
	margin-top: 8px;
	.dropdown-item {
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		padding: 0.5rem 1.25rem;
		color: var(--dark-text, #f6f6f6);

		svg {
			margin-right: 10px;
		}

		&:hover {
			background-color: transparent;
			color: var(--Primary-1);
			svg path[fill="white"]{
				fill: var(--Primary-1);
			}
		}
	}
}

.moredetailsCnt {
	display: flex;
	padding: 0px 16px;
	justify-content: center;
	align-items: flex-start;
	gap: 32px;
	margin-top: 28px;
	margin-bottom: 32px;
	flex-wrap: wrap;
	.listDetails {
		.listchild1 {
			color: var(--dark-text-secondary, #c5c5c5);
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			span.text-green {
				color: var(--dark-green, #1bfa70);
			}
			span.text-red {
				color: var(--dark-red, #fa0b00);
			}
		}
		.listchild2 {
			color: var(--dark-text, #f6f6f6);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}
	}
}

.borderbottom {
	border-bottom: 1px solid #191d22;
}

.mycollectiontabs {
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	backdrop-filter: blur(19px);
	display: inline-flex;
	border: 0;
	margin-bottom: 32px;
	min-height: 40px;
	li.nav-item {
		margin-right: 5px;
		@media (max-width: 525px) {
			width: calc(50% - 10px);
			margin-right: 0px;
			text-align: center;
			margin-bottom: 4px;
		}
		&:last-child {
			margin-right: 0px;
		}
		a.nav-link {
			color: rgba(197, 197, 197, 0.6);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			border-radius: 12px;
			border: 0;
			padding: 8px 20px;
			margin-right: 0px;
			&:hover,
			&.active {
				background: var(
					--dark-gradient,
					linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%)
				);
				color: var(--dark-bg-dark-1, #0a0a0b);
				font-weight: 600;
			}
		}
	}
}

.collectOfferBtn {
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	color: var(--dark-text, #f6f6f6);
	padding: 8px 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	border: 0;
	transition: 0.3s linear;
	&:hover {
		background: var(
			--dark-gradient,
			linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%)
		);
		color: var(--dark-bg-dark-1, #0a0a0b);
		font-weight: 600;
	}
}

body.light-mode {
	.iconBtn {
		background: var(--light-input-button, #e0e3e7);
		img {
			filter: brightness(0.2);
		}
	}
	.dropdownMenu {
		background: rgba(224, 227, 231, 0.7);
		border-color: transparent;
		backdrop-filter: blur(19px);
		.dropdown-item {
			color: var(--light-text, #14161b);
			img {
				filter: invert(0.8);
			}
			svg path[fill="white"]{
				fill: var(--light-text, #14161b);
			}
			&:hover{
				svg path[fill="white"]{
					fill: var(--Primary-1);
				}
			}
		}
	}
	
	.userDescpara {
		color: var(--light-text, #14161b);
	}
	.readmorebtn {
		color: var(--light-text-grey, #73767d);
	}
	.moredetailsCnt {
		.listDetails {
			.listchild1 {
				color: var(--light-text-grey, #73767d);
			}
			.listchild2 {
				color: var(--light-text, #14161b);
			}
		}
	}
	.borderbottom {
		border: 1px solid var(--light-input-button, #e0e3e7);
	}
	.mycollectiontabs {
		background: var(--light-input-button, #e0e3e7);
		li.nav-item {
			a.nav-link {
				color: var(--light-text-grey, #73767d);
				background-color: transparent;
				&:hover,
				&.active {
					background: var(
						--dark-gradient,
						linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%)
					);
					color: var(--Button-Text, #0a0a0b);
					font-weight: 600;
				}
			}
		}
	}
	.collectOfferBtn {
		background: var(
			--light-gradient-light,
			linear-gradient(137deg, #ffab00 6.3%, #ffd76f 95.08%)
		);
		color: var(--Button-Text, #0a0a0b);
		&:hover {
			background: var(--light-input-button, #e0e3e7);
		}
	}
}
