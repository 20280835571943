.cuschk {
    display: block;
    position: relative;
    cursor: pointer;
    height: 20px;
    width: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: relative;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #191d22;
        border: 1px solid #2e343d;
        border-radius: 3px;
    }

    &:hover input~.checkmark {
        background-color: #181b20;
        border: 1px solid #2e343d;
        border-radius: 3px;
    }

    input:checked~.checkmark {
        background-color: #ffa900;
        border: 1px solid #ffa900;
        border-radius: 3px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked~.checkmark:after {
        display: block;
    }

    .checkmark:after {
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid #0a0a0b;
        border-width: 0 1.5px 1.5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &+h3 {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: var(--dark-text, #f6f6f6);
    }
}

.tblImg {
    img video {
        width: 60px;
        height: 60px;
        border-radius: 12px;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.18);
        margin-right: 15px;
        object-fit: cover;
    }
}

.fw-600 {
    font-weight: 600;
}

.dark-text-grey {
    color: var(--dark-text-grey, #595f6a);
}

.dark-primary {
    color: var(--dark-primary-1, #ffa900);
}

.dark-text-secondary {
    color: var(--dark-text-secondary, #c5c5c5);
}

.btnBuynow {
    border-radius: 8px;
    background: var(--dark-input-button, #191d22);
    border: 0px;
    height: 28px auto;
    padding: 2px 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    min-height: 28px;
    min-width: 96px;
    width: auto;
    color: var(--dark-text, #f6f6f6);
    text-transform: capitalize;

    img {
        margin-right: 5px;
    }

    &:hover {
        border-radius: 8px;
        background: var(--dark-gradient, linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color: var(--dark-bg-dark-1, #0a0a0b);

        img {
            filter: brightness(1) invert(1);
        }
    }
}

.activityTable {
    thead {
        tr {
            th {
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                color: var(--dark-text-grey, #595f6a);

                &:nth-child(2) {
                    min-width: 250px;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                color: var(--dark-text, #f6f6f6);
                vertical-align: middle;

                &:nth-child(2) {
                    min-width: 250px;
                }

                .cancelTag {
                    display: inline-flex;
                    width: 84px;
                    height: 28px;
                    padding: 2px 6px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    background: var(--dark-input-button, #21252b);
                    color: var(--dark-red, #fe2626);
                    text-align: center;

                    &:hover {
                        background: var(--dark-red, #fe2626);
                        color: #fff;
                        cursor: pointer;
                    }
                }
            }

            &:hover {
                border-radius: 12px;

                td {
                    &:first-child {
                        border-start-start-radius: 12px;
                        border-end-start-radius: 12px;
                    }

                    &:last-child {
                        border-start-end-radius: 12px;
                        border-end-end-radius: 12px;
                    }
                }

                background: var(--dark-bg-dark-2, #15171c);
            }
        }
    }
}

.ellipsisTxt {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80px;
    white-space: nowrap;
}

.h-verCollection {
    border: 1px solid #2e343d;
    padding: 8px 16px;
    border-radius: 12px;
    background: #2d3239;
    font-size: 12px;
    font-weight: 400;
    transition: all 0.3s linear;
    margin-top: 4px;
    margin-bottom: 4px;
}

body.light-mode {

    .activityTable {
        thead {
            tr {
                th {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    color: var(--dark-text-grey, #595f6a);

                    &:nth-child(2) {
                        min-width: 250px;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    color: var(--dark-text, #f6f6f6);
                    vertical-align: middle;

                    &:nth-child(2) {
                        min-width: 250px;
                    }

                    .cancelTag {
                        border: 1px solid var(--light-borders-hover-button);
                        background: var(--light-bg-2);
                        color: var(--dark-text);

                        &:hover {
                            background: var(--Primary-1, #FFA800);
                            border-color: var(--dark-borders-hover-button);
                        }
                    }
                }

                &:hover {
                    border-radius: 12px;

                    td {
                        &:first-child {
                            border-start-start-radius: 12px;
                            border-end-start-radius: 12px;
                        }

                        &:last-child {
                            border-start-end-radius: 12px;
                            border-end-end-radius: 12px;
                        }
                    }

                    background: var(--dark-bg-dark-2, #15171c);
                }
            }
        }
    }
}