.modal-dialog.mymodal {
	@media only screen and (min-width: 576px) {
		max-width: 420px;
	}
	.modal-content {
		border-radius: 20px;
		background: var(--dark-bg-dark-2, #15171c);
		box-shadow: 2px 0px 20px 0px rgba(0, 0, 0, 0.16);
		.modal-header {
			border: 0px;
		}
		.modal-title {
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			color: var(--dark-text, #f6f6f6);
			text-align: center;
			margin: 0 auto;
		}
		.close {
			margin-left: -26px;
			z-index: 1;
		}
	}
}

.btn.btn-clear,
.removebtn {
	background-color: transparent;
	padding: 0;
	border: 0;
}

.cartItem {
	border-radius: 16px;
	background: var(--dark-input-button, #1f2329);
	backdrop-filter: blur(19px);
	display: flex;
	margin-bottom: 16px;
	.itemImg {
		width: 84px;
		height: 84px;
		margin-right: 1px;
		border-radius: 8px 0px 0px 8px;
		box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.18);
		img {
			width: 84px;
			height: 84px;
			border-radius: 8px 0px 0px 8px;
		}
	}
	.itemName {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text-secondary, #c5c5c5);
	}
	.itemId {
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
	}
	.itemBal {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text, #f6f6f6);
	}
}

.hrCls {
	border-color: #1f2329;
	margin: 20px 0px;
}

.itemCalLeft {
	color: var(--dark-text-secondary, #c5c5c5);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}

.itemCalRightLight {
	color: var(--dark-text-grey, #6e737e);
	text-align: right;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
}

.itemCalRight {
	color: var(--dark-text, #f6f6f6);
	text-align: right;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
}
.mb12px {
	margin-bottom: 12px;
}

.noteTxt {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	color: var(--dark-text-grey, #6e737e);
}

.checkoutBtn {
	border-radius: 8px;
	background: var(
		--dark-gradient,
		linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%)
	);
	padding: 8px 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	color: var(--dark-bg-dark-1, #0a0a0b);
}

.formInputs {
	.input-group {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background: var(--dark-input-button, #191d22);
		backdrop-filter: blur(19px);
		padding: 8px 12px;
		align-items: center;
		height: 40px;
		flex: 1 0 auto;
		.form-control {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
			height: auto;
			&:focus {
				outline: 0;
				box-shadow: none;
			}
		}
		&.err {
			border-color: var(--Red, #fe2626) !important;
		}
		.input-group-text {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
		}
		&:focus-within {
			border-color: #ffa900;
		}
	}
	.form-control {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background-color: var(--dark-input-button, #191d22);
		padding: 8px 16px;
		align-items: center;
		height: 40px;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text);
		&:focus {
			outline: 0;
			box-shadow: none;
			border-color: #ffa900;
		}
	}
	select {
		appearance: none;
		background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23F6F6F6%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23f6f6f6%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
		background-repeat: no-repeat;
		background-position: right 10px top 50%;
		padding-right: 40px !important;
	}
	.input-Dropdown {
		padding: 0px;
		border-radius: 0;
		font-size: 16px;
		font-style: normal;
		background-color: transparent;
		border: 0;
		font-weight: 400;
		color: var(--dark-text, #f6f6f6);
		box-shadow: none;
		outline: unset;
		&:active,
		&:focus {
			background-color: transparent !important;
		}
	}
	.ig-dmenu {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background-color: var(--dark-input-button, #191d22);

		.dropdown-item {
			color: #f6f6f6;
			font-size: 14px;
			font-style: normal;
			background-color: transparent;
			&:hover {
				color: #ffa900;
			}
		}
	}
}

.greenLabel {
	border-radius: 8px;
	background: var(--dark-input-button, #1f2329);
	height: 24px;
	padding: 4px 8px;
	align-items: center;
	display: inline-flex;
	margin-left: 10px;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	color: var(--dark-green, #00b976);
}

.zindex1 {
	z-index: 1;
}
.zindex-1 {
	z-index: -1;
}
.borderRoundedCard {
	border-radius: 16px;
	border: 1px solid var(--dark-borders-button, #2e343d);
	padding: 12px 16px;
}

.btnSecondary {
	border-radius: 8px;
	background: var(--dark-input-button, #1f2329);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	color: var(--dark-text, #f6f6f6);
	padding: 8px 20px;
	&:hover {
		background: var(
			--dark-gradient,
			linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%)
		);
		color: var(--dark-bg-dark-1, #0a0a0b);
	}
}

.dark-red {
	color: var(--dark-red, #fe2626);
}

.checkTit {
	color: var(--dark-text, #f6f6f6);
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
}
.subcheckTxt {
	color: var(--dark-text-secondary, #c5c5c5);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.mb12px {
	margin-bottom: 12px;
}

.checkoutBtn {
	border-radius: 8px;
	background: var(
		--dark-gradient,
		linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%)
	);
	padding: 8px 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	color: var(--dark-bg-dark-1, #0a0a0b);
}

.noteTxt {
	display: flex;
	align-items: center;
	font-size: 12px;
	font-style: normal;
	line-height: 16px;
	font-weight: 400;
	color: var(--dark-text-grey, #6e737e);
}

.borderBox {
	border-radius: 16px;
	border: 1px solid var(--dark-borders-hover-button, #2d3139);
	padding: 12px 16px;
	margin-bottom: 15px;
}
.innermodalcard {
	border-radius: 16px;
	background: var(--dark-input-button, #1d2025);
	backdrop-filter: blur(19px);
	padding: 8px 12px;
}

.cancelBtn {
	border-radius: 8px;
	background: var(--dark-input-button, #1d2025);
	border: 1px solid transparent;
	padding: 8px 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	color: var(--Red, #fe2626);
	&:hover {
		border-color: var(--Red, #fe2626);
		color: var(--Red, #fe2626);
	}
}

.modal-dialog.mymodal {
	@media only screen and (min-width: 576px) {
		max-width: 420px;
	}
	.modal-content {
		border-radius: 20px;
		background: var(--dark-bg-dark-2, #15171c);
		box-shadow: 2px 0px 20px 0px rgba(0, 0, 0, 0.16);
		.modal-header {
			border: 0px;
		}
		.modal-title {
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			color: var(--dark-text, #f6f6f6);
			text-align: center;
			margin: 0 auto;
		}
		.close {
			margin-left: -26px;
		}
	}
}

.btn.btn-clear,
.removebtn {
	background-color: transparent;
	padding: 0;
	border: 0;
}

.cartItem {
	border-radius: 16px;
	background: var(--dark-input-button, #1f2329);
	backdrop-filter: blur(19px);
	display: flex;
	margin-bottom: 16px;
	.itemImg {
		width: 84px;
		height: 84px;
		margin-right: 1px;
		border-radius: 8px 0px 0px 8px;
		box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.18);
		img {
			width: 84px;
			height: 84px;
			border-radius: 8px 0px 0px 8px;
		}
	}
	.itemName {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text-secondary, #c5c5c5);
	}
	.itemId {
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
	}
	.itemBal {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text, #f6f6f6);
	}
}

.hrCls {
	border-color: #1f2329;
	margin: 20px 0px;
}

.itemCalLeft {
	color: var(--dark-text-secondary, #c5c5c5);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}

.itemCalRightLight {
	color: var(--dark-text-grey, #6e737e);
	text-align: right;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
}

.itemCalRight {
	color: var(--dark-text, #f6f6f6);
	text-align: right;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
}
.mb12px {
	margin-bottom: 12px;
}

.noteTxt {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	color: var(--dark-text-grey, #6e737e);
}

.checkoutBtn {
	border-radius: 8px;
	background: var(
		--dark-gradient,
		linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%)
	);
	padding: 8px 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	color: var(--dark-bg-dark-1, #0a0a0b);
}

.formInputs {
	.input-group {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background: var(--dark-input-button, #191d22);
		backdrop-filter: blur(19px);
		padding: 8px 12px;
		align-items: center;
		height: 40px;
		flex: 1 0 auto;
		.form-control {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
			height: auto;
			&:focus {
				outline: 0;
				box-shadow: none;
				color: white;
			}
		}
		.input-group-text {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
		}
		&:focus-within {
			border-color: #ffa900;
		}
	}
	.form-control {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background-color: var(--dark-input-button, #191d22);
		padding: 8px 16px;
		align-items: center;
		height: 40px;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text);
		&:focus {
			outline: 0;
			box-shadow: none;
			border-color: #ffa900;
		}
	}
	select {
		appearance: none;
		background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23F6F6F6%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23f6f6f6%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
		background-repeat: no-repeat;
		background-position: right 10px top 50%;
		padding-right: 40px !important;
	}
	.input-Dropdown {
		padding: 0px;
		border-radius: 0;
		font-size: 16px;
		font-style: normal;
		background-color: transparent;
		border: 0;
		font-weight: 400;
		color: var(--dark-text, #f6f6f6);
		box-shadow: none;
		outline: unset;
		&:active,
		&:focus {
			background-color: transparent !important;
		}
	}
	.ig-dmenu {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background-color: var(--dark-input-button, #191d22);

		.dropdown-item {
			color: #f6f6f6;
			font-size: 14px;
			font-style: normal;
			background-color: transparent;
			&:hover {
				color: #ffa900;
			}
		}
	}
}

.greenLabel {
	border-radius: 8px;
	background: var(--dark-input-button, #1f2329);
	height: 24px;
	padding: 4px 8px;
	align-items: center;
	display: inline-flex;
	margin-left: 10px;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	color: var(--dark-green, #00b976);
}

.zindex1 {
	z-index: 1;
}
.zindex-1 {
	z-index: -1;
}
.borderRoundedCard {
	border-radius: 16px;
	border: 1px solid var(--dark-borders-button, #2e343d);
	padding: 12px 16px;
}

.btnSecondary {
	border-radius: 8px;
	background: var(--dark-input-button, #1f2329);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	color: var(--dark-text, #f6f6f6);
	padding: 8px 20px;
	&:hover {
		background: var(
			--dark-gradient,
			linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%)
		);
		color: var(--dark-bg-dark-1, #0a0a0b);
	}
}

.dark-red {
	color: var(--dark-red, #fe2626);
}

.checkTit {
	color: var(--dark-text, #f6f6f6);
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
}
.subcheckTxt {
	color: var(--dark-text-secondary, #c5c5c5);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.mb12px {
	margin-bottom: 12px;
}

.checkoutBtn {
	border-radius: 8px;
	background: var(
		--dark-gradient,
		linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%)
	);
	padding: 8px 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	color: var(--dark-bg-dark-1, #0a0a0b);
}

.noteTxt {
	display: flex;
	align-items: center;
	font-size: 12px;
	font-style: normal;
	line-height: 16px;
	font-weight: 400;
	color: var(--dark-text-grey, #6e737e);
}

.walletCnt {
	display: flex;
	padding: 12px;
	align-items: center;
	align-self: stretch;
	border-radius: 12px;
	border: 1px solid var(--dark-borders-hover-button, #2d3139);
	backdrop-filter: blur(19px);

	h3 {
		overflow: hidden;
		color: var(--dark-text, #f6f6f6);
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: 24px;
		margin-bottom: 0px;
	}
}
.walletLabel {
	display: flex;
	height: 24px;
	padding: 4px 8px;
	align-items: center;
	border-radius: 8px;
	background: var(--dark-input-button, #1d2025);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;

	&.successLabel {
		color: var(--Green, #00b976);
	}
	&.dangerLabel {
		color: var(--Red, #fe2626);
	}
}

.walletSubHeading {
	color: var(--dark-text-secondary, #c5c5c5);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.createContent {
	position: relative;
	.closeBtn {
		position: absolute;
		right: 0px;
		top: 0px;
	}
}

@media only screen and (min-width: 992px) {
	.modal-dialog.mymodal.modalLG {
		max-width: 765px;
	}
}

.warningbx {
	border-radius: 12px;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(19px);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	padding: 12px;
}

.modal-dialog.mymodal {
	@media only screen and (min-width: 576px) {
		max-width: 420px;
	}
	.modal-content {
		border-radius: 20px;
		background: var(--dark-bg-dark-2, #15171c);
		box-shadow: 2px 0px 20px 0px rgba(0, 0, 0, 0.16);
		.modal-header {
			border: 0px;
		}
		.modal-title {
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			color: var(--dark-text, #f6f6f6);
			text-align: center;
			margin: 0 auto;
		}
		.close {
			margin-left: -26px;
		}
	}
}

.btn.btn-clear,
.removebtn {
	background-color: transparent;
	padding: 0;
	border: 0;
}

.cartItem {
	border-radius: 16px;
	background: var(--dark-input-button, #1f2329);
	backdrop-filter: blur(19px);
	display: flex;
	margin-bottom: 16px;
	.itemImg {
		width: 84px;
		height: 84px;
		margin-right: 1px;
		border-radius: 8px 0px 0px 8px;
		box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.18);
		img {
			width: 84px;
			height: 84px;
			border-radius: 8px 0px 0px 8px;
		}
	}
	.itemName {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text-secondary, #c5c5c5);
	}
	.itemId {
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
	}
	.itemBal {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text, #f6f6f6);
	}
}

.hrCls {
	border-color: #1f2329;
	margin: 20px 0px;
}

.itemCalLeft {
	color: var(--dark-text-secondary, #c5c5c5);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}

.itemCalRightLight {
	color: var(--dark-text-grey, #6e737e);
	text-align: right;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
}

.itemCalRight {
	color: var(--dark-text, #f6f6f6);
	text-align: right;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
}
.mb12px {
	margin-bottom: 12px;
}

.noteTxt {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	color: var(--dark-text-grey, #6e737e);
}

.checkoutBtn {
	border-radius: 8px;
	background: var(
		--dark-gradient,
		linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%)
	);
	padding: 8px 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	color: var(--dark-bg-dark-1, #0a0a0b);
}

.formInputs {
	.input-group {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background: var(--dark-input-button, #191d22);
		backdrop-filter: blur(19px);
		padding: 8px 12px;
		align-items: center;
		height: 40px;
		flex: 1 0 auto;
		.form-control {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
			height: auto;
			&:focus {
				outline: 0;
				box-shadow: none;
			}
		}
		.input-group-text {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
		}
		&:focus-within {
			border-color: #ffa900;
		}
	}
	.form-control {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background-color: var(--dark-input-button, #191d22);
		padding: 8px 16px;
		align-items: center;
		height: 40px;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text);
		&:focus {
			outline: 0;
			box-shadow: none;
			border-color: #ffa900;
		}
	}
	select {
		appearance: none;
		background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23F6F6F6%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23f6f6f6%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
		background-repeat: no-repeat;
		background-position: right 10px top 50%;
		padding-right: 40px !important;
	}
}

.greenLabel {
	border-radius: 8px;
	background: var(--dark-input-button, #1f2329);
	height: 24px;
	padding: 4px 8px;
	align-items: center;
	display: inline-flex;
	margin-left: 10px;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	color: var(--dark-green, #00b976);
}

.mk-offer {
	.dropdown-item {
		div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 65%;
		}
	}
}

.traitFilterDropdown {
	position: relative;
	.filterSubmenuModal {
		position: absolute;
		left: 0px;
		top: 45px;
		width: calc(100% - 0px);
		border-radius: 8px;
		background: rgba(37, 42, 49, 0.7);
		padding: 12px 16px;
		backdrop-filter: blur(19px);
		z-index: 1;
		max-height: 315px;
		overflow: auto;
		.filterhead {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 2px 0px;
			span {
				&.heading {
					overflow: hidden;
					color: var(--dark-text-secondary, #c5c5c5);
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px;
					display: block;
				}
			}
		}

		.filterSubHead {
			h3 {
				overflow: hidden;
				color: var(--dark-text, #f6f6f6);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				margin-bottom: 0px;
			}
		}

		.filterRow {
			display: flex;
			justify-content: space-between;
			align-items: start;
			margin-bottom: 10px;

			.filterTxt {
				overflow: hidden;
				color: var(--dark-text, #f6f6f6);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px;
				margin-bottom: 0px;
			}

			p {
				overflow: hidden;
				color: var(--dark-text-secondary, #c5c5c5);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px;
				margin-bottom: 0px;
			}

			:hover {
				opacity: 0.75;
			}
		}

		hr {
			opacity: 0.1;
			background: var(--dark-white, #fff);
		}
	}
}

.trait-loader {
	width: 100%;

	div {
		margin: auto;
	}
}

body.light-mode {
	.modal-dialog.mymodal .modal-content textarea {
		border: 1px solid var(--light-borders-hover-button);
		background: var(--light-input-button);
		-webkit-backdrop-filter: blur(19px);
		backdrop-filter: blur(19px);
		color: var(--light-text);
	}
}