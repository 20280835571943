.hrcls {
    border-top: 1px solid var(--dark-borders-hover-button);
    margin-bottom: 10px;
    margin-top: 10px;
}


@mixin btngreenred($color: #D02222) {
    background-color: $color;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 400;
    border-radius: 50px;
    color: #fff;
    padding: 4px 15px;
    min-width: 85px;
    &:hover {
        background-color: transparent;
        border: 1px solid $color;
        color: $color;
    }
}

.btnOpen {
    @include btngreenred($color: red);

    &:hover {
        color: white; 
    }
}



.btnnew {
    @include btngreenred($color: #FFA900); 
    color: black;
}

.btnpending {
    @include btngreenred($color: #2a78d7);
    
    &:hover {
        color: white; 
    }
}

.btnClose {
    @include btngreenred($color: green)
}


.custom-radio .custom-control-label::before {
    border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
    background-color: var(--dark-input-button, #191d22);
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    border-color: #FFA900;
    background-color: transparent;
    background-image: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
}

.messageChatBox {
    min-height: 100px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .userMessage,
    .adminMessage {
        width: 55%;
        margin-bottom: 50px;
    }

    .adminMessage {
        margin-left: auto;
    }

    .messagebx {
        padding: 15px 24px;
        background-color: var(--dark-input-button, #191d22);
        font-size: 16px;
        font-weight: 400;
        border-radius: 0px 36px 36px 36px;
        width: 100%;
        margin-bottom: 5px;
    }
}