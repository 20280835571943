.cl_banner {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: calc(100% - 96px);
		background: linear-gradient(180deg,
				#090a0c 0%,
				rgba(9, 10, 12, 0.62) 53.12%,
				rgba(9, 10, 12, 0) 100%);
	}

	&::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(0deg,
				#090a0c 0%,
				rgba(9, 10, 12, 0.62) 38.19%,
				rgba(9, 10, 12, 0) 100%);
		height: 96px;
	}
}

.userDetails {
	.imgtag {
		text-align: center;
		margin-top: -50px;
		margin-bottom: 30px;

		img {
			width: 120px;
			height: 120px;
			border-radius: 12px;
			box-shadow: 0px 0px 27px 0px #fff;
		}

		span {
			width: 120px;
			height: 120px;
			border-radius: 12px;
		}
	}

	.userName {
		font-size: 20px;
		font-family: var(--font-russo);
		font-style: normal;
		font-weight: 400;
		line-height: 32px;
		margin-bottom: 4px;
	}

	.usernameby {
		span.fw-400 {
			font-weight: 400;
			color: #f6f6f6;
		}

		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 12px;
	}
}

.userDescpara {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	color: var(--dark-text, #f6f6f6);
	margin-bottom: 4px;
}

.readmorebtn {
	background-color: transparent;
	border: 0;
	padding: 0;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	color: var(--dark-text-grey, #595f6a);
}

.socialmediaBtnGrid {
	display: grid;
	grid-template-columns: 40px 40px 40px;
	gap: 10px;
	margin-top: 15px;

	.dropdownMenu {
		border-radius: 8px;
		background: rgba(37, 42, 49, 0.7);
		backdrop-filter: blur(19px);
		margin-top: 8px;
		padding: 0.5rem 0;

		.dropdown-item {
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			padding: 0.5rem 1.25rem;
			color: var(--dark-text, #f6f6f6);

			svg {
				margin-right: 10px;
			}

			&:hover {
				background-color: transparent;
				color: var(--Primary-1);

				svg path[fill="white"] {
					fill: var(--Primary-1);
				}
			}
		}
	}

	.iconBtn {
		border-radius: 12px;
		background: var(--dark-input-button, #191d22);
		border: 0px;
		width: 40px;
		height: 40px;
		text-align: center;
		box-shadow: none !important;
		outline: none !important;

		&:hover,
		&:focus {
			background: var(--dark-gradient,
					linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%)) !important;
		}
	}
}

.moredetailsCnt {
	display: flex;
	padding: 0px 16px;
	justify-content: center;
	align-items: flex-start;
	gap: 32px;
	margin-top: 28px;
	margin-bottom: 32px;
	flex-wrap: wrap;

	.listDetails {
		.listchild1 {
			color: #726565;
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;

			span.text-green {
				color: var(--dark-green, #1bfa70);
			}

			span.text-red {
				color: var(--dark-red, #fa0b00);
			}
		}

		.listchild2 {
			color: var(--dark-text, #f6f6f6);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}
	}
}

.borderbottom {
	border-bottom: 1px solid #191d22;
}

.mycollectiontabs {
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	backdrop-filter: blur(19px);
	display: inline-flex !important;
	border: 0 !important;
	margin-bottom: 32px;

	li.nav-item {
		margin-right: 5px;

		&:last-child {
			margin-right: 0px;
		}

		a.nav-link {
			color: rgba(197, 197, 197, 0.6);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			border-radius: 12px;
			border: 0;
			padding: 8px 20px;
			margin-right: 0px;

			&:hover,
			&.active {
				background: var(--dark-gradient,
						linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
				color: var(--dark-bg-dark-1, #0a0a0b);
				font-weight: 600;
			}
		}
	}
}

.collectOfferBtn {
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	color: var(--dark-text, #f6f6f6);
	padding: 8px 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	border: 0;
	transition: 0.3s linear;

	&:hover {
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
		color: var(--dark-bg-dark-1, #0a0a0b);
		font-weight: 600;
	}
}

.itemWizard {
	display: flex;

	.filterSection {
		width: 308px;
		flex: 0 0 308px;
		transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);

		&.show {
			transform: translateX(0);
			flex: 0 0 308px;
		}

		&.hide {
			transform: translateX(-300%);
			flex: 0 0 0;
			width: 0;
			opacity: 0;
			visibility: hidden;
		}
	}

	.viewItemscard {
		transition: all 0.5s linear;

		&.collapseWidth {
			width: calc(100% - 308px);
			margin-left: 28px;
		}

		&.fullWidth {
			width: calc(100% - 0px);
			margin-left: 0px;
		}
	}
}

@media (max-width: 991px) {
	.itemWizard {
		display: block;

		.filterSection {
			position: absolute;
			width: 308px;
			z-index: 10;

			&.show {
				transform: translateX(0);
			}

			&.hide {
				transform: translateX(-300%);
				flex: 0 0 0;
				width: 0;
				opacity: 0;
				visibility: hidden;
			}
		}

		.viewItemscard {
			transition: all 0.5s linear;

			&.collapseWidth {
				width: calc(100% - 0px);
				margin-left: 0px;
			}

			&.fullWidth {
				width: calc(100% - 0px);
				margin-left: 0px;
			}
		}
	}
}

.primeBtn {
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	color: var(--dark-text, #f6f6f6);
	padding: 8px 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	border: 0;
	transition: 0.3s linear;

	&:hover {
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
		color: var(--dark-bg-dark-1, #0a0a0b);
		font-weight: 600;
	}
}

.formInputs {
	.input-group {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background: var(--dark-input-button, #191d22);
		backdrop-filter: blur(19px);
		padding: 8px 12px;
		align-items: center;
		height: 40px;
		flex: 1 0 auto;

		.form-control {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
			height: auto;

			&:focus {
				outline: 0;
				box-shadow: none;
			}
		}

		.input-group-text {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
		}

		&:focus-within {
			border-color: #ffa900;
		}
	}

	.form-control {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background-color: var(--dark-input-button, #191d22);
		padding: 8px 16px;
		align-items: center;
		height: 40px;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text);

		&:focus {
			outline: 0;
			box-shadow: none;
			border-color: #ffa900;
		}
	}

	select {
		appearance: none;
		background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23F6F6F6%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23f6f6f6%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
		background-repeat: no-repeat;
		background-position: right 10px top 50%;
		padding-right: 40px !important;
	}
}

.ItemsTabsViewTabs {
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	backdrop-filter: blur(19px);

	li.nav-item {
		a.nav-link {
			width: 40px;
			height: 40px;
			padding: 8px;
			border-radius: 12px;
			text-align: center;

			&.active {
				background: var(--dark-borders-button-text-inactive, #2e343d);
			}
		}
	}
}

.filtershowhideBtn {
	border-radius: 0px 12px 12px 0px;
	background: var(--dark-input-button, #191d22);
	padding: 10px;
	border: 0;
}

.noItemFound {
	text-align: center;
	margin: 50px 15px;

	.nofounttxt {
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		color: var(--dark-text, #f6f6f6);
		margin-top: -6px;
	}

	.applyBtn {
		border-radius: 12px;
		border: 0;
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
		width: 268px;
		padding: 8px 20px;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		color: var(--dark-bg-dark-1, #0a0a0b);
	}

	.applyBtn1 {
		border-radius: 12px;
		border: 0;
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
		width: 187px;
		padding: 8px 20px;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		color: var(--dark-bg-dark-1, #0a0a0b);
	}
}

.customdropdown {
	button.btn {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background: var(--dark-input-button, #191d22);
		height: 40px;
		text-transform: capitalize;

		&:focus {
			outline: 0;
			box-shadow: none;
			border-color: #ffa900;
		}
	}

	.dropdown-menu {
		border-radius: 8px;
		background: rgba(37, 42, 49, 0.7);
		backdrop-filter: blur(19px);
	}

	.dropdown-item {
		display: flex;
		justify-content: space-between;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text, #f6f6f6);
		padding: 5px 10px;
		min-width: 220px;

		&:hover,
		&:focus {
			background: var(--dark-gradient,
					linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
			color: var(--dark-bg-dark-1, #0a0a0b);
		}
	}
}

@media (min-width: 1201px) {
	.filterbtnshow {
		position: absolute;
		top: 0px;
	}
}

.filtercard {
	border-radius: 16px;
	background: var(--dark-bg-dark-2, #15171c);
	padding: 8px 0px;
	width: 100%;

	.filterTit {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: transparent;
		border: 0;
		width: 100%;
		padding: 5px 20px;
		outline: 0;
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: 600;

		i.fas {
			padding: 0px 5px;
		}

		&:hover,
		&:focus,
		&:active,
		&:not(:disabled):active {
			box-shadow: none !important;
			outline: 0;
			background-color: transparent;
		}
	}
}

.filtersubTit {
	padding: 5px 20px 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	color: var(--dark-text-secondary, #c5c5c5);
}

.collapseCnt {
	padding: 5px 20px;
}

.filterInnercard {
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	padding: 16px;
}

.rightcounttxt {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	color: var(--dark-text-secondary, #c5c5c5);
}

.filterStatusbtn {
	border-radius: 8px;
	background: var(--dark-input-button, #191d22);
	padding: 4px 8px;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	border: 0px;
	margin-right: 12px;
	margin-bottom: 12px;
	color: var(--dark-text, #f6f6f6);

	&.active,
	&:hover {
		border-radius: 8px;
		color: var(--dark-bg-dark-1, #0a0a0b);
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
	}
}

.formInputs {
	.input-group {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background: var(--dark-input-button, #191d22);
		backdrop-filter: blur(19px);
		padding: 8px 12px;
		align-items: center;
		height: 40px;
		flex: 1 0 auto;

		.form-control {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
			height: auto;

			&:focus {
				outline: 0;
				box-shadow: none;
			}
		}

		.input-group-text {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
		}

		&:focus-within {
			border-color: #ffa900;
		}
	}

	.form-control {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background-color: var(--dark-input-button, #191d22);
		padding: 8px 16px;
		align-items: center;
		height: 40px;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text);

		&:focus {
			outline: 0;
			box-shadow: none;
			border-color: #ffa900;
		}
	}

	select {
		appearance: none;
		background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23F6F6F6%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23f6f6f6%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
		background-repeat: no-repeat;
		background-position: right 10px top 50%;
		padding-right: 30px !important;
	}
}

.rightTxt {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	color: var(--dark-text, #f6f6f6);
}

.applybtn {
	border-radius: 12px;
	background: var(--dark-gradient,
			linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	color: var(--dark-bg-dark-1, #0a0a0b);
	padding: 6px 20px;
	border: 0;
	display: block;
	width: 100%;

	&:hover {
		background: var(--dark-input-button, #191d22);
		color: var(--dark-text, #f6f6f6);
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.32;
	}
}

.applybtn1 {
	border-radius: 12px;
	background: var(--dark-gradient,
			linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	color: var(--dark-bg-dark-1, #0a0a0b);
	padding: 6px 20px;
	border: 0;
	display: block;
	width: 100%;

	&:hover {
		background: var(--dark-input-button, #191d22);
		color: var(--dark-text, #f6f6f6);
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.32;
	}
}

.htcheckbox {
	max-height: 275px;
	overflow: auto;
	padding-right: 10px;
	margin-right: -10px;
}

.scroller::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	border-radius: 0px;
}

.scroller::-webkit-scrollbar-thumb {
	background-color: #2e343d;
	border-radius: 10px;
}

.scroller::-webkit-scrollbar-track {
	background-color: transparent;
}

@-moz-document url-prefix() {
	.scroller {
		scrollbar-width: thin;
		scrollbar-color: #2e343d;
	}
}

.cuschk {
	display: block;
	position: relative;
	cursor: pointer;
	height: 20px;
	width: 20px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input {
		position: relative;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #191d22;
		border: 1px solid #2e343d;
		border-radius: 3px;
	}

	&:hover input~.checkmark {
		background-color: transparent;
		border: 1px solid #2e343d;
		border-radius: 3px;
	}

	input:checked~.checkmark {
		background-color: #ffa900;
		border: 1px solid #ffa900;
		border-radius: 3px;
	}

	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	input:checked~.checkmark:after {
		display: block;
	}

	.checkmark:after {
		left: 7px;
		top: 3px;
		width: 5px;
		height: 10px;
		border: solid #0a0a0b;
		border-width: 0 1.5px 1.5px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	&+h3 {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text, #f6f6f6);
	}
}

.tblImg {
	img {
		width: 60px;
		height: 60px;
		border-radius: 12px;
		box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.18);
		margin-right: 15px;
	}
}

.fw-600 {
	font-weight: 600;
}

.dark-text-grey {
	color: var(--dark-text-grey, #595f6a);
}

.dark-primary {
	color: var(--dark-primary-1, #ffa900);
}

.dark-text-secondary {
	color: var(--dark-text-secondary, #c5c5c5);
}

.btnBuynow {
	border-radius: 8px;
	background: var(--dark-input-button, #191d22);
	border: 0px;
	height: 28px;
	padding: 2px 6px;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	width: 96px;
	color: var(--dark-text, #f6f6f6);

	&:hover {
		border-radius: 8px;
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		color: var(--dark-bg-dark-1, #0a0a0b);
	}
}

.listViewTable {
	thead {
		tr {
			th {
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				color: var(--dark-text-grey, #595f6a);
			}
		}
	}

	tbody {
		tr {
			td {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				color: var(--dark-text, #f6f6f6);
				vertical-align: middle;

				&:nth-child(2) {
					min-width: 300px;
				}
			}

			&:hover {
				border-radius: 12px;

				td {
					&:first-child {
						border-start-start-radius: 12px;
						border-end-start-radius: 12px;
					}

					&:last-child {
						border-start-end-radius: 12px;
						border-end-end-radius: 12px;
					}
				}

				background: var(--dark-bg-dark-2, #15171c);
			}
		}
	}
}

.cuschk {
	display: block;
	position: relative;
	cursor: pointer;
	height: 20px;
	width: 20px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input {
		position: relative;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #191d22;
		border: 1px solid #2e343d;
		border-radius: 3px;
	}

	&:hover input~.checkmark {
		background-color: transparent;
		border: 1px solid #2e343d;
		border-radius: 3px;
	}

	input:checked~.checkmark {
		background-color: #ffa900;
		border: 1px solid #ffa900;
		border-radius: 3px;
	}

	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	input:checked~.checkmark:after {
		display: block;
	}

	.checkmark:after {
		left: 7px;
		top: 3px;
		width: 5px;
		height: 10px;
		border: solid #0a0a0b;
		border-width: 0 1.5px 1.5px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	&+h3 {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text, #f6f6f6);
	}
}

.tblImg {
	img {
		width: 60px;
		height: 60px;
		border-radius: 12px;
		box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.18);
		margin-right: 15px;
	}
}

.fw-600 {
	font-weight: 600;
}

.dark-text-grey {
	color: var(--dark-text-grey, #595f6a);
}

.dark-primary {
	color: var(--dark-primary-1, #ffa900);
}

.dark-text-secondary {
	color: var(--dark-text-secondary, #c5c5c5);
}

.btnBuynow {
	border-radius: 8px;
	background: var(--dark-input-button, #191d22);
	border: 0px;
	height: 28px auto;
	padding: 2px 10px;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	min-height: 28px;
	min-width: 96px;
	width: auto;
	color: var(--dark-text, #f6f6f6);

	img {
		margin-right: 5px;
	}

	&:hover {
		border-radius: 8px;
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		color: var(--dark-bg-dark-1, #0a0a0b);

		img {
			filter: brightness(1) invert(1);
		}
	}
}

.activityTable {
	thead {
		tr {
			th {
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				color: var(--dark-text-grey, #595f6a);
			}
		}
	}

	tbody {
		tr {
			td {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				color: var(--dark-text, #f6f6f6);
				vertical-align: middle;

				&:nth-child(2) {
					min-width: 300px;
				}
			}

			&:hover {
				border-radius: 12px;

				td {
					&:first-child {
						border-start-start-radius: 12px;
						border-end-start-radius: 12px;
					}

					&:last-child {
						border-start-end-radius: 12px;
						border-end-end-radius: 12px;
					}
				}

				background: var(--dark-bg-dark-2, #15171c);
			}
		}
	}
}

.ellipsisTxt {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 80px;
	white-space: nowrap;
}

.analyticsCard {
	border-radius: 16px;
	background: var(--dark-bg-dark-2, #15171c);
	padding: 16px;
	margin-bottom: 28px;
}

.greenTxt {
	color: var(--dark-green, #00b976);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
}

.redTxt {
	color: var(--dark-red, #fa0b00);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
}

.cardTit {
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
}

.graphvalue {
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
}

.analyticsTable {
	thead {
		tr {
			th {
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				color: var(--dark-text-grey, #595f6a);
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}

	tbody {
		tr {
			td {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				color: var(--dark-text, #f6f6f6);
				vertical-align: middle;
				padding-left: 5px;
				padding-right: 5px;

				&:nth-child(1) {
					min-width: 200px;
				}
			}

			&:hover {
				border-radius: 12px;

				td {
					&:first-child {
						border-start-start-radius: 12px;
						border-end-start-radius: 12px;
					}

					&:last-child {
						border-start-end-radius: 12px;
						border-end-end-radius: 12px;
					}
				}

				background: var(--dark-bg-dark-2, transparent);
			}
		}
	}
}

.tblImg {
	img {
		width: 40px;
		height: 40px;
		border-radius: 8px;
		box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.18);
		margin-right: 15px;
	}
}

.cusProgress.progress {
	border-radius: 4px;
	background: var(--dark-input-button, #1f2329);
	height: 12px;

	.progress-bar {
		border-radius: 4px;
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
	}
}

.mb12px {
	margin-bottom: 12px;
}

.progressList {
	margin-bottom: 20px;
}

.heightTbl {
	max-height: 390px;
	overflow: auto;
	padding-right: 10px;
	margin-right: -10px;
}

.scroller::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	border-radius: 0px;
}

.scroller::-webkit-scrollbar-thumb {
	background-color: #2e343d;
	border-radius: 10px;
}

.scroller::-webkit-scrollbar-track {
	background-color: transparent;
}

@-moz-document url-prefix() {
	.scroller {
		scrollbar-width: thin;
		scrollbar-color: #2e343d;
	}
}

.itemWizard {
	display: flex;

	.filterSection {
		width: 308px;
		flex: 0 0 308px;
		transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);

		&.show {
			transform: translateX(0);
			flex: 0 0 308px;
		}

		&.hide {
			transform: translateX(-300%);
			flex: 0 0 0;
			width: 0;
			opacity: 0;
			visibility: hidden;
		}
	}

	.viewItemscard {
		transition: all 0.5s linear;

		&.collapseWidth {
			width: calc(100% - 308px);
			margin-left: 28px;
		}

		&.fullWidth {
			width: calc(100% - 0px);
			margin-left: 0px;
		}
	}
}

@media (max-width: 991px) {
	.itemWizard {
		display: block;

		.filterSection {
			position: absolute;
			width: 308px;
			z-index: 10;

			&.show {
				transform: translateX(0);
			}

			&.hide {
				transform: translateX(-300%);
				flex: 0 0 0;
				width: 0;
				opacity: 0;
				visibility: hidden;
			}
		}

		.viewItemscard {
			transition: all 0.5s linear;

			&.collapseWidth {
				width: calc(100% - 0px);
				margin-left: 0px;
			}

			&.fullWidth {
				width: calc(100% - 0px);
				margin-left: 0px;
			}
		}
	}
}

.primeBtn {
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	color: var(--dark-text, #f6f6f6);
	padding: 8px 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	border: 0;
	transition: 0.3s linear;

	&:hover {
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
		color: var(--dark-bg-dark-1, #0a0a0b);
		font-weight: 600;
	}
}

.formInputs {
	.input-group {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background: var(--dark-input-button, #191d22);
		backdrop-filter: blur(19px);
		padding: 8px 12px;
		align-items: center;
		height: 40px;
		flex: 1 0 auto;

		.form-control {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
			height: auto;

			&:focus {
				outline: 0;
				box-shadow: none;
			}
		}

		.input-group-text {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
		}

		&:focus-within {
			border-color: #ffa900;
		}
	}

	.form-control {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background-color: var(--dark-input-button, #191d22);
		padding: 8px 16px;
		align-items: center;
		height: 40px;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text);

		&:focus {
			outline: 0;
			box-shadow: none;
			border-color: #ffa900;
		}
	}

	select {
		appearance: none;
		background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23F6F6F6%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23f6f6f6%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
		background-repeat: no-repeat;
		background-position: right 10px top 50%;
		padding-right: 40px !important;
	}
}

.ItemsTabsViewTabs {
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	backdrop-filter: blur(19px);

	li.nav-item {
		a.nav-link {
			width: 40px;
			height: 40px;
			padding: 8px;
			border-radius: 12px;
			text-align: center;

			&.active {
				background: var(--dark-borders-button-text-inactive, #2e343d);
			}
		}
	}
}

.filtershowhideBtn {
	border-radius: 0px 12px 12px 0px;
	background: var(--dark-input-button, #191d22);
	padding: 10px;
	border: 0;
	position: relative;
}



table {
	&.progressTable {
		border-collapse: separate;
		border-spacing: 0 15px;

		tbody {
			&.trdtable_body {
				tr {
					position: relative;
					overflow: hidden;
					padding: 1px;
					border: 1px solid #0a0a0b;
					background: #0a0a0b;
					transition: all 0.5s;

					&:hover {
						border: 1px solid #0a0a0b;
						background: #15171c;

						td {
							.progressBarCnt {
								background: #2e343d;
								z-index: 0;
							}
						}
					}

					td {
						padding: 14px 20px;

						.progressBarCnt {
							position: absolute;
							left: 1px;
							top: 1px;
							height: calc(100% - 2px);
							width: 50%;
							max-width: calc(calc(100% - 2px));
							background: #2e343d;
							border-radius: 16px;
							transition: all 0.5s;
							z-index: 0;
						}

						.tdValue {
							position: relative;
							z-index: 1;
						}
					}
				}
			}
		}
	}
}

.fs-14 {
	font-size: 14px;
}

.fw-400 {
	font-weight: 400;
}

.fs-16 {
	font-size: 16px;
}

.fw-600 {
	font-weight: 600;
}

.dark-text-secondary {
	color: var(--dark-text-secondary, #c5c5c5);
}

.dark-text {
	color: var(--dark-text, #f6f6f6);
}

.filterofferscard {
	padding: 8px 16px;
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	font-size: 12px;
	font-weight: 400;
	@extend .dark-text-secondary;
	transition: all 0.3s linear;
	border: 1px solid transparent;

	&.active,
	&:hover {
		background: linear-gradient(3.03deg, #191d22 -2.75%, #191d22 95.06%) padding-box, linear-gradient(134.9deg,
				#ffa900 0%,
				rgba(255, 255, 255, 0) 51.38%,
				#ffdd65 100%) border-box;
		border: 1px solid transparent;
	}


}

.spanTextRight {
	width: 75px;
	text-align: right;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: top;
}

.filtercard {
	border-radius: 16px;
	background: var(--dark-bg-dark-2, #15171c);
	padding: 8px 0px;
	width: 100%;

	.filterTit {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: transparent;
		border: 0;
		width: 100%;
		padding: 5px 20px;
		outline: 0;
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: 600;

		i.fas {
			padding: 0px 5px;
		}

		&:hover,
		&:focus,
		&:active,
		&:not(:disabled):active {
			box-shadow: none !important;
			outline: 0;
			background-color: transparent;
		}
	}
}

.filtersubTit {
	padding: 5px 20px 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	color: var(--dark-text-secondary, #c5c5c5);
}

.collapseCnt {
	padding: 5px 20px;
}

.filterInnercard {
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	padding: 16px;
}

.rightcounttxt {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	color: var(--dark-text-secondary, #c5c5c5);
}

.cuschk {
	display: block;
	position: relative;
	cursor: pointer;
	height: 20px;
	width: 20px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input {
		position: relative;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #191d22;
		border: 1px solid #2e343d;
		border-radius: 3px;
	}

	&:hover input~.checkmark {
		background-color: transparent;
		border: 1px solid #2e343d;
		border-radius: 3px;
	}

	input:checked~.checkmark {
		background-color: #ffa900;
		border: 1px solid #ffa900;
		border-radius: 3px;
	}

	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	input:checked~.checkmark:after {
		display: block;
	}

	.checkmark:after {
		left: 7px;
		top: 3px;
		width: 5px;
		height: 10px;
		border: solid #0a0a0b;
		border-width: 0 1.5px 1.5px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	&+h3 {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text, #f6f6f6);
	}
}

.filterStatusbtn {
	border-radius: 8px;
	background: var(--dark-input-button, #191d22);
	padding: 4px 8px;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	border: 0px;
	margin-right: 12px;
	margin-bottom: 12px;
	color: var(--dark-text, #f6f6f6);

	&.active,
	&:hover {
		border-radius: 8px;
		color: var(--dark-bg-dark-1, #0a0a0b);
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
	}
}

.formInputs {
	.input-group {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background: var(--dark-input-button, #191d22);
		backdrop-filter: blur(19px);
		padding: 8px 12px;
		align-items: center;
		height: 40px;
		flex: 1 0 auto;

		.form-control {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
			height: auto;

			&:focus {
				outline: 0;
				box-shadow: none;
			}
		}

		.input-group-text {
			background-color: transparent;
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
		}

		&:focus-within {
			border-color: #ffa900;
		}
	}

	.form-control {
		border-radius: 12px;
		border: 1px solid var(--dark-borders-button-text-inactive, #2e343d);
		background-color: var(--dark-input-button, #191d22);
		padding: 8px 16px;
		align-items: center;
		height: 40px;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text);

		&:focus {
			outline: 0;
			box-shadow: none;
			border-color: #ffa900;
		}
	}

	select {
		appearance: none;
		background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23F6F6F6%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23f6f6f6%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
		background-repeat: no-repeat;
		background-position: right 10px top 50%;
		padding-right: 30px !important;
	}
}

.rightTxt {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	color: var(--dark-text, #f6f6f6);
}

.applybtn {
	border-radius: 12px;
	background: var(--dark-gradient,
			linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	color: var(--dark-bg-dark-1, #0a0a0b);
	padding: 6px 20px;
	border: 0;
	display: block;
	width: 100%;

	&:hover {
		background: var(--dark-input-button, #191d22);
		color: var(--dark-text, #f6f6f6);
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.32;
	}
}

.htcheckbox {
	max-height: 275px;
	overflow: auto;
	padding-right: 10px;
	margin-right: -10px;
}

.scroller::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	border-radius: 0px;
}

.scroller::-webkit-scrollbar-thumb {
	background-color: #2e343d;
	border-radius: 10px;
}

.scroller::-webkit-scrollbar-track {
	background-color: transparent;
}

@-moz-document url-prefix() {
	.scroller {
		scrollbar-width: thin;
		scrollbar-color: #2e343d;
	}
}

.cmntxt {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	color: #726565;
}

.btn-refeash {
	background-color: transparent;
	padding: 0;
	border: 0;

	&:hover {
		svg path[fill='#C5C5C5'] {
			fill: #ffa900;
		}
	}
}

.primeBtn {
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	color: var(--dark-text, #f6f6f6);
	padding: 8px 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	border: 0;
	transition: 0.3s linear;

	&:hover {
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
		color: var(--dark-bg-dark-1, #0a0a0b);
		font-weight: 600;
	}
}

.clearAllBtn {
	border-radius: 12px;
	background: var(--dark-input-button, #191d22);
	color: var(--dark-text, #f6f6f6);
	padding: 0px 20px;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	border: 0;
	transition: 0.3s linear;

	&:hover {
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
		color: var(--dark-bg-dark-1, #0a0a0b);
		font-weight: 600;
	}
}

.fiterSelectlabel {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	.tagLabel {
		border-radius: 8px;
		background: var(--dark-input-button, #191d22);
		min-height: 28px;
		padding: 4px 8px;
		display: inline-flex;
		align-items: center;
		margin-right: 10px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		color: var(--dark-text, #f6f6f6);
		margin-bottom: 8px;

		.background {
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			color: var(--dark-text-grey, #595f6a);
			padding-right: 10px;
		}
	}
}

.cartBtnCircle {
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
	background: var(--dark-gradient,
			linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
	border: 0;
}

.gridItemCard {
	border-radius: 12px;
	position: relative;
	background: var(--dark-bg-dark-2, #15171c);
	box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.18);

	&.active {
		border: 3px solid transparent;
		background: linear-gradient(0deg, rgba(21, 23, 28, 0), rgba(21, 23, 28, 0)) padding-box,
			linear-gradient(135deg,
				rgba(255, 169, 0, 1) 0%,
				rgba(255, 255, 255, 0) 50%,
				rgba(255, 237, 174, 1) 100%) border-box;
	}

	.gItemImg {
		position: relative;
		border-radius: 12px 12px 0 0;

		img.itemImage,
		video {
			min-height: 168px;
			max-height: 168px;
			border-radius: 12px 12px 0 0;
			vertical-align: middle;
		}
	}

	.hoverImgCnt {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0;
		transform: translateY(50%);
		background-color: rgba($color: #000000, $alpha: 0.6);
		padding: 12px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		transition: all 0.5s ease-in-out;
		border-radius: 12px 12px 0px 0px;
		z-index: 1;
	}

	.botcntCard {
		padding: 8px;
		position: relative;
		height: 85px;
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;

		.bottomSmImg {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			border-bottom-left-radius: 12px;
			border-bottom-right-radius: 12px;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: -1px;
				background: rgba(13, 13, 13, 0.8);
				backdrop-filter: blur(19px);
				border-bottom-left-radius: 12px;
				border-bottom-right-radius: 12px;
			}

			img {
				width: 100%;
				height: 73px;
				border-bottom-left-radius: 12px;
				border-bottom-right-radius: 12px;
			}
		}
	}

	.botcntTxt {
		position: relative;

		.itemName {
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			padding-bottom: 8px;
			overflow: hidden;
			width: 100%;
			color: var(--dark-text, #f6f6f6);
			text-overflow: ellipsis;
		}

		.itemPricetxt {
			padding-top: 8px;
			color: var(--dark-text-secondary, #c5c5c5);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
		}

		.itemPriceval {
			padding-top: 8px;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			color: var(--dark-text, #f6f6f6);
		}
	}

	.cointypeImg {
		width: 28px;
		height: 28px;
		border-radius: 50%;
	}

	.alarmbadge {
		font-size: 12px;
		font-weight: 400;
		padding: 5px 8px;
		color: var(--dark-text, #f6f6f6);
		border-radius: 8px;
		background: rgba(0, 0, 0, 0.24);
		backdrop-filter: blur(19px);
	}

	.buybowbtn {
		border-radius: 12px;
		border: 0;
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
		padding: 4px 20px;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		color: var(--dark-bg-dark-1, #0a0a0b);

		&:hover {
			background: var(--dark-input-button, #191d22);
			color: var(--dark-text, #f6f6f6);
		}
	}

	&:hover {
		.hoverImgCnt {
			opacity: 1;
			transform: translateY(0px);
		}
	}
}



.tblImg {

	img,
	video {
		width: 60px;
		height: 60px;
		border-radius: 12px;
		box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.18);
		margin-right: 15px;
	}
}

.fw-600 {
	font-weight: 600;
}

.dark-text-grey {
	color: var(--dark-text-grey, #595f6a);
}

.dark-primary {
	color: var(--dark-primary-1, #ffa900);
}

.dark-text-secondary {
	color: var(--dark-text-secondary, #c5c5c5);
}

.btnBuynow {
	border-radius: 8px;
	background: var(--dark-input-button, #191d22);
	border: 0px;
	padding: 2px 6px;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	min-width: 96px;
	color: var(--dark-text, #f6f6f6);

	&:hover {
		border-radius: 8px;
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		color: var(--dark-bg-dark-1, #0a0a0b);
	}
}

.listViewTable,
.activityTable,
.progressTable {
	@media (max-width:767px) {
		min-width: 800px;
	}

	thead {
		tr {
			th {
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				color: var(--dark-text-grey, #595f6a);
			}
		}
	}

	tbody {
		tr {
			td {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				color: var(--dark-text, #f6f6f6);
				vertical-align: middle;

				&:nth-child(2) {
					min-width: 300px;
				}

				.fas {
					color: #fff;
				}

				.profile_iconBtn__TPPPd {
					&:hover {
						.fas {
							color: #0a0a0b;
						}
					}
				}
			}

			&:hover {
				border-radius: 12px;

				td {
					&:first-child {
						border-start-start-radius: 12px;
						border-end-start-radius: 12px;
					}

					&:last-child {
						border-start-end-radius: 12px;
						border-end-end-radius: 12px;
					}
				}

				background: var(--dark-bg-dark-2, #15171c);
			}
		}
	}
}

body.light-mode {
	.btnBuynow {
		background: var(--light-input-button);
		color: var(--light-text);

		img {
			filter: brightness(0.3);
		}

		&:hover {
			background: var(--light-gradient-light,
					linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
			color: var(--bg-dark-1);
		}
	}

	.listViewTable,
	.activityTable,
	.progressTable {
		thead {
			tr {
				th {
					color: var(--light-text-grey);
				}
			}
		}

		tbody {
			tr {
				td {
					color: var(--light-text);

					.fas {
						color: #fff;
					}

					.profile_iconBtn__TPPPd {
						&:hover {
							.fas {
								color: #0a0a0b;
							}
						}
					}
				}

				&:hover {
					background: var(--light-bg-2);
				}
			}
		}
	}

	.socialmediaBtnGrid .dropdownMenu .dropdown-item {
		svg path[fill="white"] {
			fill: var(--light-text, #14161b);
		}

		&:hover {
			svg path[fill="white"] {
				fill: var(--Primary-1);
			}
		}
	}

	.cusProgress.progress {
		background: var(--light-input-button);
	}
}

/*---------------------------------------Skeleton---------------------------------------------------------*/
.banner-skeleton {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: calc(100% - 96px);
	}
}

.details-skeleton {
	.imgtag {
		text-align: center;
		margin-top: -50px;
		margin-bottom: 30px;

		span {
			width: 120px;
			height: 120px;
			border-radius: 12px;
		}
	}

	.userName {
		line-height: 32px;
		margin-bottom: 4px;
		margin-top: auto;
		margin-right: auto;
		margin-left: auto;
		width: 40%;
	}

	.usernameby {
		width: 30%;
	}

	.userDescpara {
		line-height: 20px;
		margin-bottom: 4px;
	}

	.readmorebtn {
		line-height: 20px;
		width: 20%;
	}
}

.stat-skeleton {
	padding: 0px 16px;
	margin-top: 28px;
	margin-bottom: 32px;

	span {
		height: 32px;
		width: 100%;
		border-radius: 5px;
	}
}

.nft-grid-skeleton {
	.gridrow {
		display: grid;
		gap: 28px;
		transition: all 1s linear;

		@media (min-width: 1400px) {
			&.g-col-5 {
				--grid-column: 5;
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * calc(var(--grid-column) - 1))) / var(--grid-column)) [col-end]);
			}

			&.g-col-6 {
				--grid-column: 6;
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * calc(var(--grid-column) - 1))) / var(--grid-column)) [col-end]);
			}
		}

		@media only screen and (max-width: 1399px) and (min-width: 1200px) {
			&.g-col-5 {
				--grid-column: 3;
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * calc(var(--grid-column) - 1))) / var(--grid-column)) [col-end]);
			}

			&.g-col-6 {
				--grid-column: 4;
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * calc(var(--grid-column) - 1))) / var(--grid-column)) [col-end]);
			}
		}

		@media only screen and (max-width: 1199px) and (min-width: 992px) {
			&.g-col-5 {
				--grid-column: 3;
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * calc(var(--grid-column) - 1))) / var(--grid-column)) [col-end]);
			}

			&.g-col-6 {
				--grid-column: 4;
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * calc(var(--grid-column) - 1))) / var(--grid-column)) [col-end]);
			}
		}

		@media only screen and (max-width: 991px) and (min-width: 768px) {
			&.g-col-5 {
				--grid-column: 3;
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * calc(var(--grid-column) - 1))) / var(--grid-column)) [col-end]);
			}

			&.g-col-6 {
				--grid-column: 3;
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * calc(var(--grid-column) - 1))) / var(--grid-column)) [col-end]);
			}
		}

		@media only screen and (max-width: 767px) and (min-width: 600px) {
			&.g-col-5 {
				--grid-column: 3;
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * calc(var(--grid-column) - 1))) / var(--grid-column)) [col-end]);
			}

			&.g-col-6 {
				--grid-column: 3;
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * calc(var(--grid-column) - 1))) / var(--grid-column)) [col-end]);
			}
		}

		@media only screen and (max-width: 526px) and (min-width: 599px) {
			&.g-col-5 {
				--grid-column: 2;
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * calc(var(--grid-column) - 1))) / var(--grid-column)) [col-end]);
			}

			&.g-col-6 {
				--grid-column: 2;
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * calc(var(--grid-column) - 1))) / var(--grid-column)) [col-end]);
			}
		}

		@media only screen and (max-width: 525px) {
			&.g-col-5 {
				grid-template-columns: auto;
			}

			&.g-col-6 {
				grid-template-columns: auto;
			}
		}
	}

	.gridrow {
		display: grid;
		gap: 28px;
		transition: all 1s linear;

		@media (min-width: 1200px) {
			&.g-col-5 {
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * 4)) / 5) [col-end]);
			}

			&.g-col-6 {
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * 5)) / 6) [col-end]);
			}
		}

		@media only screen and (max-width: 1199px) and (min-width: 992px) {
			&.g-col-5 {
				grid-template-columns: auto auto auto auto;
			}

			&.g-col-6 {
				grid-template-columns: repeat(auto-fit,
						[col-start] calc(calc(100% - calc(28px * 4)) / 5) [col-end]);
			}
		}

		@media only screen and (max-width: 991px) and (min-width: 768px) {
			&.g-col-5 {
				grid-template-columns: auto auto auto auto;
			}

			&.g-col-6 {
				grid-template-columns: auto auto auto auto;
			}
		}

		@media only screen and (max-width: 767px) and (min-width: 600px) {
			&.g-col-5 {
				grid-template-columns: auto auto auto;
			}

			&.g-col-6 {
				grid-template-columns: auto auto auto;
			}
		}

		@media only screen and (max-width: 526px) and (min-width: 599px) {
			&.g-col-5 {
				grid-template-columns: auto auto;
			}

			&.g-col-6 {
				grid-template-columns: auto auto;
			}
		}

		@media only screen and (max-width: 525px) {
			&.g-col-5 {
				grid-template-columns: auto;
			}

			&.g-col-6 {
				grid-template-columns: auto;
			}
		}

		.grid-item {
			border-radius: 12px;
			min-height: 220px;
			max-height: 228px;
			position: relative;

			.img-placeholder {
				position: absolute;
				top: 5%;
				left: 50%;
				transform: translateX(-50%);
				border-radius: 12px;
				width: 85%;
				height: 60%;
			}

			.item-img {
				border-radius: 12px;
				width: 100%;
				height: 100%;
				position: relative;
			}

			.item-details {
				position: absolute;
				height: 30%;
				bottom: 0;
				left: 0;
				right: 0;

				.details {
					padding: 10px;
					gap: '5px';

					span {
						width: 80%;
					}
				}
			}
		}
	}
}


.recharts-bar-rectangle:hover {
	fill: #ffcc00;
}





.light-mode .userDescpara {
	color: #000000;
}

body.light-mode {
	.mycollectiontabs li.nav-item a.nav-link {
		color: var(--light-text-grey, #73767d);
	}

	.iconBtn {
		background: var(--light-input-button, #e0e3e7);

		img {
			filter: brightness(0.2);
		}
	}

	.dropdownMenu {
		background: rgba(224, 227, 231, 0.7);
		border-color: transparent;
		backdrop-filter: blur(19px);

		:global(.dropdown-item) {
			color: var(--light-text, #14161b);

			img {
				filter: invert(0.8);
			}
		}
	}

	.userDescpara {
		color: var(--light-text, #14161b);
	}

	.readmorebtn {
		color: var(--light-text-grey, #73767d);
	}

	.moredetailsCnt {
		:global(.listDetails) {
			:global(.listchild1) {
				color: var(--light-text-grey, #73767d);
			}

			:global(.listchild2) {
				color: var(--light-text, #14161b);
			}
		}
	}

	.borderbottom {
		border: 1px solid var(--light-input-button, #e0e3e7);
	}

	.mycollectiontabs {
		background: var(--light-input-button, #e0e3e7);

		li:global(.nav-item) {
			a:global(.nav-link) {
				color: var(--light-text-grey, #73767d);
				background-color: transparent;

				&:hover,
				&:global(.active) {
					background: var(--dark-gradient,
							linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%));
					color: var(--Button-Text, #0a0a0b);
					font-weight: 600;
				}
			}
		}
	}

	.collectOfferBtn {
		background: var(--light-gradient-light,
				linear-gradient(137deg, #ffab00 6.3%, #ffd76f 95.08%));
		color: var(--Button-Text, #0a0a0b);

		&:hover {
			background: var(--light-input-button, #e0e3e7);
		}
	}
}

body.light-mode {
	.analyticsCard {
		background: var(--dark-bg-dark-2, #f2f3f5);
	}
}

body.light-mode .analyticsTable tbody tr td {
	color: var(--light-text);
}

body.light-mode .usernameby {
	span.fw-400 {
		color: #000000;
	}
}

.filterGroup {
	position: relative;

	.collectionFilter {
		max-height: 230px;
		overflow-y: scroll;

		&.filterSubmenu {
			width: 100%;
			border-radius: 8px;
			background: rgba(37, 42, 49, 0.7);
			backdrop-filter: blur(19px);
			padding: 12px 16px;
			position: absolute;
			left: 0px;
			top: 45px;
			z-index: 2;

			.filterRow {
				width: 100%;

				h3 {
					&.heading {
						color: var(--dark-text, #f6f6f6);
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: 24px;
						margin-bottom: 2px;
					}
				}

				p {
					overflow: hidden;
					color: var(--dark-text-secondary, #c5c5c5);
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px;
					margin-bottom: 2px;

					span {
						color: var(--dark-text, #f6f6f6);
					}
				}
			}

			hr {
				background: var(--dark-white, #fff);
				opacity: 0.1;
			}
		}
	}
}

.filterSubHead {
	h3 {
		overflow: hidden;
		color: var(--dark-text, #f6f6f6);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: 0px;
	}
}

body.light-mode {
	.dark-text-secondary {
		color: var(--light-text-secondary);
	}

	.filterofferscard {
		background: var(--light-input-button);

		&:hover {
			background: var(--light-borders-hover-button);
		}
	}
}